import { Injectable } from '@angular/core';
import {  HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from '../../node_modules/rxjs';
import { TelemetryService } from '@infosys-it/angular-telemetry-sdk';
import { Appconstants } from './shared/constants/app-constants';
//import {environment} from '../environments/environment.prod';

    
@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor { 
    //private telemetryService: TelemetryService,
    constructor(private telemetryService: TelemetryService) {
        console.log("interceptor const", localStorage.getItem("access-token"));
        
    }
    private barerToken: string;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
         /* for testing in local take the barertoken from https://keycloakauth.azurewebsites.net/Home/About
              and replace below, while giving the production build uncomment this.barerToken and
              comment the hardcoded token
            */
 this.barerToken = localStorage.getItem("access-token");
// this.barerToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImtnMkxZczJUMENUaklmajRydDZKSXluZW4zOCIsImtpZCI6ImtnMkxZczJUMENUaklmajRydDZKSXluZW4zOCJ9.eyJhdWQiOiJiMTg3NDYyNy1lMzlkLTQ5NzYtYjM3MC1hMGVlN2UzODNhMjQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82M2NlN2Q1OS0yZjNlLTQyY2QtYThjYy1iZTc2NGNmZjVlYjYvIiwiaWF0IjoxNjAyMDc0ODQ2LCJuYmYiOjE2MDIwNzQ4NDYsImV4cCI6MTYwMjA3ODc0NiwiYWNyIjoiMSIsImFpbyI6IkFWUUFxLzhSQUFBQVlYRG13cmxNYUEwdGowRmRldE9HMHdnVkRUR2k5Y1hkU3NKY0Z0cC96NnlWOVJsYjVRSlQ3bjk4T0JxTFNtNU9RS1U5d2VmQitQQ1RpSWdtblNlMXBCZnZuZGR2cUdIVGNyNjhuOVZwVlYwPSIsImFtciI6WyJwd2QiLCJyc2EiLCJ3aWEiLCJtZmEiXSwiYXBwaWQiOiJiMTg3NDYyNy1lMzlkLTQ5NzYtYjM3MC1hMGVlN2UzODNhMjQiLCJhcHBpZGFjciI6IjEiLCJkZXZpY2VpZCI6IjU1MTg1NWU4LWM0YzItNDljZC1hNzgyLTlkZTgxYmY5NDQ5ZiIsImZhbWlseV9uYW1lIjoiQmhhdnNhciIsImdpdmVuX25hbWUiOiJQb29uYW0iLCJpcGFkZHIiOiI0Ny4yNDcuOTEuODAiLCJuYW1lIjoiUG9vbmFtIEJoYXZzYXIiLCJvaWQiOiJlNjczODFiMC0xZmU3LTQ4ZmEtYTc4OS0yYWQ2NjNiMzEzYmQiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtMjY2NzQ5OTQwLTE2Mzc5NjQ0NDQtOTI5NzAxMDAwLTM2MzA1NzgiLCJyaCI6IjAuQVFZQVdYM09ZejR2elVLb3pMNTJUUDlldGlkR2g3R2Q0M1pKczNDZzduNDRPaVFHQU5RLiIsInNjcCI6IlVzZXIuUmVhZCIsInN1YiI6Inlob2JXYlpBXzA3cU9wQ2g0V0p5ZE1LTFBsdWZ6a0F2VF9nbW9uSlZhYnciLCJ0aWQiOiI2M2NlN2Q1OS0yZjNlLTQyY2QtYThjYy1iZTc2NGNmZjVlYjYiLCJ1bmlxdWVfbmFtZSI6InBvb25hbS5iaGF2c2FyQGFkLmluZm9zeXMuY29tIiwidXBuIjoicG9vbmFtLmJoYXZzYXJAYWQuaW5mb3N5cy5jb20iLCJ1dGkiOiJJTVo3a1NBQUFVT0h1cjRLb0R4U0FBIiwidmVyIjoiMS4wIiwiRW1wTm8iOiIxMDM1MzMyIn0.j8e0cXkclad7_2SDsD_sXghfoFEcHRwsMahhwCA8ehYO3a4d0q0ZyWwr6dou0RFFLjQgtu141U4mauJms3Up9gPDyYMKkDinTSQQ-WJWBcj44zTME3cnOY3osd8Vik1Ow5wlHmyaWm1tJJhXf-cQSWcbck6xTtm4QCKe5-b3YhMA0qr1qwKLENsHs7XV1yNYmPWBktHQ8vk9jx-Qv4bBryohjlsY6lpsm08IIz35UL-ziAkrSadFUPiKqy-Jp0mg0hxer_03P1KMKVfkrxyH2-BYrmsOXouk1GrI506RSYqOmUVD9UMS6QRO0DsaQZsUfXCNjY86xgacA8gjOmiYkQ';

 // this.barerToken = sessionStorage.getItem('accessToken');
        const newRequest = req.clone({ headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' +
             this.barerToken
          })
        });
        return next.handle(newRequest).pipe(tap(
        succ => console.log('Success' + succ),
        err => {
            console.log("error interceptor");
            console.log(err);
            // if (err.status === Appconstants.unAuthorized)    {
            //     console.log('you are not authenticated');
            // }

           

            // // comment it for refresh issue starts
            // if (err.status === 401) {
            //     // auto logout if 401 response returned from api
            //     if (!this.authService.isTokenExpired()) {
            //         // logout if token is expired
            //         this.authService.logout();
            //         location.reload(true);
            //     }
            //     this.authService.logout();
            //     location.reload(true);
            // }
            // comment it for refresh issue ends
            let errorMsg = '';
            if (err.error instanceof ErrorEvent) {
                errorMsg = `Error: ${err.error.message}`;
            }
            else {
                errorMsg = `Error Code: ${err.status},  Message: ${err.message}`;
            }
            let ErrorEventData = {
                err: err.status.toString(),
                errtype: "Interceptor Error",
                stacktrace: errorMsg,
                pageid: Appconstants.interceptor,
                object: "",
                plugin: ""
            }
            this.telemetryService.error(ErrorEventData);
        }
    )
    );
}
}


