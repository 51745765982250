import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { Appconstants } from './shared/constants/app-constants';
import { EnvLoaderService } from 'src/environments/environmentLoader.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';


@Injectable()
export class AuthGuardKeycloak extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        console.log('--------------', state.url);
        const parsedUrl = new URL(document.location.href);
        const baseUrl = parsedUrl.origin;
        this.keycloakAngular.login({ redirectUri: baseUrl +  state.url });
        return;
      }
      console.log('role restriction given at app-routing.module for this route', route.data.roles);
      console.log('User roles coming after login from keycloak :', this.roles);
      const requiredRoles = route.data.roles;
      let granted: boolean = false;
      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true;
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }

      if (granted === false) {
        this.router.navigate(['/']);
      }
      resolve(granted);

    });
  }
}