import { JavaServiceModel } from '../models/javaservice.model';
import { Appconstants } from '../constants/app-constants';

export class JavaServiceBase {

  setModelForJavaService(name: string, queryParam: string,url: string, requestBody: string, type: string): string {
    let model: JavaServiceModel = {name:"", queryParam:"",url: "", requestBody: "",    type: ""};
    model.name= name;
    model.queryParam = queryParam;
    model.url= url;
    model.requestBody = requestBody;
    model.type= type;
    return JSON.stringify(model);
  }

  convertToDateString(momentDate): string {
    console.log("inside base class");
    console.log(momentDate);
    let dateCurr = '';
    let month = -1;
    let year = '';
    let strDateString: string = "";
    if (momentDate != Appconstants.empty && momentDate != Appconstants.undefined) {
      for (let date in momentDate['_i']) {
        if (date === Appconstants.dateString || date === '2') {
          if (momentDate['_i'][date] <= 9) {
            dateCurr = '0' + momentDate['_i'][date];
          }
          else {
            dateCurr = momentDate['_i'][date];
          }
        }
        if (date === Appconstants.monthString || date === '1') {
          month = momentDate['_i'][date];
        }
        if (date === Appconstants.yearString || date === '0') {
          year = momentDate['_i'][date];
        }
      }
      strDateString = dateCurr + '-' + this.getMonthString(month) + '-' + year;
    }

    return strDateString;
  }


  getMonthString(month: number) {
    let value: string;
    switch (month) {
      case 0: {
        value = 'Jan'
        break;
      }
      case 1: {
        value = 'Feb'
        break;
      }
      case 2: {
        value = 'Mar'
        break;
      }
      case 3: {
        value = 'Apr'
        break;
      }
      case 4: {
        value = 'May'
        break;
      }
      case 5: {
        value = 'Jun'
        break;
      }
      case 6: {
        value = 'Jul'
        break;
      }
      case 7: {
        value = 'Aug'
        break;
      }
      case 8: {
        value = 'Sep'
        break;
      }
      case 9: {
        value = 'Oct'
        break;
      }
      case 10: {
        value = 'Nov'
        break;
      }
      case 11: {
        value = 'Dec'
        break;
      }
      default: {
        break;
      }
    }
    return value;
  }

}
