import { Component, OnInit } from '@angular/core';
import { AppUtilServices } from 'src/app/shared/services/app-util.services';
import { NavBarService } from './nav-bar.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  blnLoad: boolean = true;
  objNavBar: object[] = [];
  config: PerfectScrollbarConfigInterface = {
    suppressScrollY: false,
    wheelPropagation: true
  };
  constructor(private codeCp: AppUtilServices, private navBarService: NavBarService,private router :Router) { 
  
  }

  ngOnInit() {

  }
}
