export enum ComponentName {
    sectionDrivenTabView= "Section driven tab view",
    tabviewtype2= "Tab View Type 2",
    accordion="accordion",
    labelViewType5="Label View Type 5",
    genericHome= "Generic Home",
    cardviewtype26="card view type 26",
    payslip="payslip",
    optionalHoliday="optional Holiday",
    totalRewards="total rewards",
    boa="Basket of Allowances",
    fileuploadgrid= "file upload grid",
    attendance="attendance",
    labelViewType1="label view type 1",
    previewCard="Preview card",
    multilabelView="Multilabel View",
    labelViewType7="label view type 7",
    labelViewsTypeVertical="label view type vertical",
    piechart='pie chart',
    cardViewType1="card view type 1",
    cardViewType2="card view type 2",
    cardViewType3="card view type 3",
    cardViewType6="card view type 6",
    cardViewType7="card view type 7",
    cardViewType10="card view type 10",
    cardViewType16="card view type 16",
    cardViewType17="card view type 17",
    cardViewType18="card view type 18",
    cardViewType28="card view type 28",
    cardViewType29="card view type 29",
    cardViewType30="card view type 30",
    cardViewType22="card view type 22",
    cardViewType27="card view type 27",
    cardViewType32="card view type 32",
    cardViewType37="card view type 37",
    cardViewType33="card view type 33",
    cardViewType34="card view type 34",
    cardViewType35="card view type 35",
    cardViewType36="card view type 36",
    cardWithCarousel="card with carousel",
    cardViewType38="card view type 38",
    cardViewType39="card view type 39",
    cardViewType40="card view type 40",
    cardViewWork="card view work Experience",
    cardViewLink="card view links",
    cardViewEvents="card view events",
    balance='balance',
    filters="Filters",
    genmodel = "genmodel",
    sda = "SectionDrivenAccordion",
    inboxView =" Inbox View",
    inboxViewType4 =" Inbox View Type 4",
    inboxexpandandcollapse =" Inbox View Expand And Collapse",
    inboxformmanage="Inbox Form Manage",
    inboxboxforvlookup="Inbox for look up",
    inboxsubview =" Inbox Subview",
    filter = "Filter",
    cardViewType23 = "Card View Type 23",
    tabAccordion = "Tab Accordion",
    cardViewType15 = "Card View Type 15",
    generiModelPopup = "Generic Model Popup",
    tabViewType5 = "Tab View Type 5",
    dependentPrfl = "Dependent Profile",
    editPrfl = "Edit Profile",
    editDependent = "Edit Dependent",
    uniqueId = "unique id",
    uniqueids="unique ids",
    editUnqIds = "edit unique ids",
    race = "race",
    raceEthinicity = "race and ethinicity",  
    disabilitydetails = "Disability Details",
    disabilityprofile = "Disability Profile",
    attendanceprofile = "Attendance Profile",
    editnationalid = "Edit National Id",
    educationProfile = "Education Profile",
    academics = "Academics",
    workExperience = "Work Experience",
    experienceProfile = "Experience Profile",
    languages = "Languages",
    languageprofile = "Language Profile",
    editLanguage = "Edit Language",
    searchFrontEnd = "Search Front End",
    dpConsent= "DP Consent",
    fileupload= "File Upload",
    fileuploadwithdownload= "File Upload with Download",
    textboxview= "TEXTBOX VIEW",
    vlookuptype61= "vlookup type61",
    vlookuptype61withchild= "vlookup type61 with Child",
    vlookuptype62= "vlookup type62",
    workflow= "Work Flow",
    genericfooter= "Generic Footer",
    guidedflow="Guided Flow",
    simpleTextBox="Simple Text Box",
    recommendation="Recommendation",
    htmlContent="Html Content",
    genericViews="Generic Views",
    jobsCardsView="jobs cards view",
    fieldtype86="field type86",
    labelViewType2="Label View Type 2",
    contacts="contacts",
    genericEmployee="Generic Employee",
    genmodelchild="gen model child",
    feedback="feedback",
    emailaddresschange="email address change",
    header="header",
 

}