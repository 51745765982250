import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Appconstants } from './shared/constants/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
 
export class AppComponent {
  title = 'GAP';
  isAppUp:boolean=false;
  msgObj:any;

  constructor(private router: Router) {
    this.checkIsAppDown();
  }

  checkIsAppDown() {
    this.msgObj = Appconstants.AppDownJSON[0];
    console.log("app down obj in app component ", this.msgObj)
    if(this.msgObj.isAppUp == "1") {
      console.log("if condition");
      this.isAppUp = true;
    }
    else {
      console.log("else condition");
      this.isAppUp = false;
      this.router.navigate([""]);
    }
  }
}
