import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  login(){
    console.log("in login")
    this.router.navigate(['app-wall/app-generichome/generictemplate/'+ environment.dpConsentModuleId]);
  }

  fnNavigateToRegistration(){
    this.router.navigate(['app-registration']);
  }
}
