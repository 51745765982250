import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IMPRESSIONEVENTS, INTERACTEVENTS, TelemetryService } from '@infosys-it/angular-telemetry-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageSnackBarService } from 'src/app/shared/component/message-snackbar/message-snackbar.service';
import { environment } from 'src/environments/environment.prod';
import { GenerictemplateService } from '../form/generictemplate/generictemplate.service';
import { Appconstants } from '../shared/constants/app-constants';
import { ComponentName } from '../shared/constants/component-name.constants';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  @ViewChild('lgContent2', { static: true }) lgContent2;
  @Output() attachment = new EventEmitter();
  rating: number;
  feedbackFormData: any;
  feedbackFormFieldsData: any;
  isStarSelected: boolean[] = [false, false, false, false, false];
  strMessage: any;
  blnValidationmsg: boolean;

  constructor(private genericTemplateService: GenerictemplateService, private modalService: NgbModal, private messageservice: MessageSnackBarService,private telemetryService: TelemetryService) { }

  ngOnInit() {
    this.fnLogImpression();
    this.openLgModal(this.lgContent2);
  }
  ngOnChanges() {
  }
  openLgModal(content) {
    this.modalService.open(content, { windowClass: 'web_custom_modal DSA_modal-lg', backdrop: "static" });
    this.fnServicecall();
  }
  // On close of popup
  closeModal() {
    this.attachment.emit(false);
  }
  // As popup opens method call(Has a service call)
  fnServicecall() {
    let url = environment.feedbackGet;
    this.genericTemplateService.fetchJson(url).subscribe((data: any) => {
      console.log(data, "genmodel json data");
      this.feedbackFormData = data;
    })
  }
  // On click of stars this method gets called. No service call
  selectStars(index): any {
    this.rating = index + 1;
    for (var i = 0; i <= this.isStarSelected.length - 1; i++) {
      if (i <= index) {
        this.isStarSelected[i] = true;
      }
      else {
        this.isStarSelected[i] = false;
      }
    }
    console.log("final rating", this.rating);
    this.feedbackFormData['fields'].forEach(element => {
      if (element.type == '14') {
        element.data = this.rating;
      }
    })

  }
  // After user gives feedback,On click of submit this method gets called(Sending the form data(entire json) to Service Call)
  fnSubmit(action) {
    //Validation is done at first. If the user didnt give star rating then Validationerrormsg displays
    console.log(action,"action")
    this.blnValidationmsg = false;
    this.feedbackFormData['fields'].forEach(element => {
      console.log(element)
      if (element.type == '14' && (element.data == null || element.data == '')) {
        this.blnValidationmsg = true;
      }
    });
    // If no validationerrormsg then servicecall happpens.
    if (this.blnValidationmsg == false) {
      console.log("in submit", this.feedbackFormData, this.feedbackFormData['fields']);
      this.genericTemplateService.postMethodCall(action['value'], this.feedbackFormData).subscribe((data: any) => {
        console.log(data, "servicedata")
        if (data !== null) {
          this.fnLogInteract("feedback_submitted");
          if (data['message'] == 'Thank you for sharing feedback') {
            this.strMessage = data['message'];
            console.log("Hello inside postMethod call")
            this.messageservice.openMessageBar({ type: 'success', message: data['message'] });
            this.modalService.dismissAll();
            this.closeModal();
          }
          else {
            this.fnLogInteract("feedback_serviceerror")
            console.log("Hello inside postMethod call error")
            this.messageservice.openMessageBar({ type: 'error', message: 'Feedback not saved' });
            this.closeModal();


          }
        }
      })
    }

  }
  fnLogImpression(){
    let impressionEventData = {
      type: IMPRESSIONEVENTS.VIEW,
      pageid: ComponentName.feedback,
      uri: window.location.href
    };
  this.telemetryService.impression(impressionEventData);
  }
  fnLogInteract(tabName) {
    let telemetryEventData = {
      type: INTERACTEVENTS.CLICK,
      id: tabName + "_tabclick",
      pageid: ComponentName.feedback
    };
    this.telemetryService.interact(telemetryEventData);
  }
}


