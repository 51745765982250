
import {Component, OnInit, ElementRef, HostListener, ViewChild, Pipe } from '@angular/core';
import { AppUtilServices} from './../shared/services/app-util.services';
import {NgForm} from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {FormControl, Validators} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatChipInputEvent, MatRadioChange, MatCalendar, DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepickerInputEvent, MatTooltip, MatDialogClose } from '@angular/material';
import { TelemetryService, INTERACTEVENTS, IMPRESSIONEVENTS } from '@infosys-it/angular-telemetry-sdk';
import { FormsModule, FormGroup, FormBuilder} from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Observer } from 'rxjs';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import { GenerictemplateService } from '../form/generictemplate/generictemplate.service';
import { environment } from 'src/environments/environment.prod';
import { RegistrationformService } from './registrationform.service';
import { error } from 'protractor';
import { Console } from 'console';
import {MessageSnackBarService} from '../shared/component/message-snackbar/message-snackbar.service';
import { Appconstants } from '../shared/constants/app-constants';



@Component({
  selector: 'app-registrationform',
  templateUrl: './registrationform.component.html',
  styleUrls: ['./registrationform.component.scss']
})

export class RegistrationformComponent implements OnInit {

  constructor(private registrationService:RegistrationformService, private geneticservice: GenerictemplateService , private http: HttpClient, private formBuilder: FormBuilder,private telemetryService: TelemetryService,private _eref: ElementRef,private router: Router,private modalService:NgbModal,private codeCp: AppUtilServices, private _sanitizer: DomSanitizer,private messageservice: MessageSnackBarService) { }


   newRequestForm: FormGroup;   
   obj:any;
   date = new Date();
   SrvResponse: any;
   flag:number=0;
   isEnabled=false;

 public selectedPrivacy: string="";

 radioselection: boolean=false;
 Error: string;
 success: boolean=false;
 Message: string;
 
 captchaURL:any;
 captchaHeader:any;
 imageUrl:any;
 dpoContent:any;
 dpoVersion:any;
 showLoader: boolean = false;
 
  ngOnInit() {
     this.registrationService.getCaptchaService().subscribe(res=>{
     this.captchaURL=new Blob([res.body]);
     this.imageUrl=URL.createObjectURL(this.captchaURL);
     this.imageUrl=this._sanitizer.bypassSecurityTrustUrl(this.imageUrl);
      this.captchaHeader=res.headers.get('Accept-Captcha');
      //console.log("captcha headers",+ this.captchaHeader);
     
  },
   (error)=>{
     //debugger
     console.log(error);
     console.log("No captcha header"+error);
     
  } 
   );

    this.newRequestForm = this.formBuilder.group({
      empNo: ['', [Validators.required] ],
      emailId: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')] ],
      firstName: ['', [Validators.required],Validators.pattern('[a-zA-Z]+[a-zA-Z\s]*$') ],
      lastName: ['', [Validators.required],Validators.pattern('[a-zA-Z]+[a-zA-Z\s]*$')],

      mobNo: ['', [Validators.required] ],
      month: ['', [Validators.required] ],
      year: ['', [Validators.required] ],
      captcha: ['', [Validators.required] ],
      dpo: ['', [Validators.required] ],
      privacy:['',[Validators.required] ],
    });

    this.yearsList();

    //Fetching DPo content
    this.registrationService.getDpoService().subscribe(res =>{
      // console.log("DPO content " + res.dpoContent);
      // console.log("DPO content " + res.dpoVersion);
      this.dpoContent=res.dpoContent;
      this.dpoVersion=res.dpoVersion;
    },(error)=>{
      console.log("Dpo service " + error);
    }
   
    );

   
  }

  yearss: object[]=[];
    
 month2:object[]=[
   {value: '1', viewValue: 'Jan'},
   {value: '2', viewValue: 'Feb'},
   {value: '3', viewValue: 'March'},
   {value: '4', viewValue: 'April'},
   {value: '5', viewValue: 'May'},
   {value: '6', viewValue: 'June'},
   {value: '7', viewValue: 'July'},
   {value: '8', viewValue: 'Aug'},
   {value: '9', viewValue: 'Sept'},
   {value: '10', viewValue: 'Oct'},
   {value: '11', viewValue: 'Nov'},
   {value: '12', viewValue: 'Dec'}
 ];

yearsList():any{
 var min=1984;
 var max=new Date().getFullYear();
 for( var i=max; i>=min; i--){
    this.yearss.push({i});
 }
 console.log(this.yearss);

 return this.yearss;
}

onClick() {
  console.log(this.newRequestForm);
 // debugger
  this.obj = {
    "employeenumber":(this.newRequestForm.controls.empNo.value.trim()).toString(),
    "personalMailId":this.newRequestForm.controls.emailId.value.trim(),
    "firstName":this.newRequestForm.controls.firstName.value.trim(),
    "lastName":this.newRequestForm.controls.lastName.value.trim(),
    "mobileNumber":(this.newRequestForm.controls.mobNo.value.trim()).toString(),
    "month":(this.newRequestForm.controls.month.value).toString(),
    "year":(this.newRequestForm.controls.year.value).toString(),
    "captcha":this.newRequestForm.controls.captcha.value.trim(),
    // "dpo":this.newRequestForm.controls.dpo.value,
    "dpoVersion":(this.dpoVersion).toString(),
    "captchaHeader":(this.captchaHeader),//Session Captcha Header
  
  };

  
  if(this.selectedPrivacy=="No"){
    this.radioselection=true
  }else if(this.selectedPrivacy=="Ok"){
    this.radioselection=false;
  }else{
    this.radioselection=true;
    this.Error="please select privacy option.";
  }
  if(this.obj.employeenumber=="" || this.obj.personalMailId==""|| this.obj.firstName=="" ||
    this.obj.lastName==""|| this.obj.mobileNumber=="" || this.obj.personalMailId==""||
    this.obj.month==""|| this.obj.year==""||this.obj.captcha=="" || this.radioselection==true ){

     this.ShowGenericError("error","Enter all the details.");
     }else{
       this.showLoader=true;
     this.registrationService.getService('',this.obj,'POST').subscribe(res  =>{
      this.SrvResponse=res;
      if(this.SrvResponse.status=="E"){
        this.showLoader=false;
        this.ShowGenericError("error",this.SrvResponse.message);
      }else{
        this.showLoader=false;
        this.Error=null;
        this.radioselection=false;
        this.success=true;
        this.ShowGenericError("success",this.SrvResponse.message);
      /// this.newRequestForm.reset();
      // this.newRequestForm.controls.
       //this.resetFormGroup();
       this.fnGenerateCaptchaRefresh();
      }
    },
    (error)=> {
      this.showLoader=false;
      this.radioselection=true;
      //this.Error="We are unable to process your request. Please try again later. If the issue persists, please write to us at alumni@infosys.com.";
      this.messageservice.openMessageBar({type:'error',message:"We are unable to process your request. Please try again later. If the issue persists, please write to us at alumni@infosys.com."})
    }
    
    );   
    
     }

}

public values11: any = [];

windowOPen: boolean;
captcha = new FormControl('');

open(content) {
  let htmlContent:any;
  htmlContent=content;

  this.modalService.open(content,{windowClass:'web_custom_modal DSA_modal-xlg', backdrop: 'static', keyboard: false})
  }
   
  openLgModal(content){​​​​​
        this.modalService.open(content,{​​​​​windowClass:'web_custom_modal DSA_modal-lg'}​​​​​)
    
        }​​​​​
 
  
        
        radioPrivacyChange(event: MatRadioChange) {​​​​​
          if(event.value=="Ok" && this.flag==0){​​​​​ 
             $("this").prop('checked', true);
              this.flag=1;
              // console.log("Flage " + this.flag)
              this.isEnabled=true;
          }​​​​​else {​​​​​
            $("this").prop('checked', false);
            this.flag=0;
            // console.log("Flage " + this.flag)
            this.isEnabled=false;
          }​​​​​

          this.selectedPrivacy=event.value;
        }​​​​​

  ShowGenericError(type,message){
this.messageservice.openMessageBar({type:type,message:message});
    // if(type=="error"){
    //     this.Error=message;
    //       this.radioselection=  true;
    //       setTimeout(() => this.hideMessage(),15000);
    // } else{
    //   this.Message=message;
    //   setTimeout(() =>this.hideMessage(), 5000);
    // }
  
  }

  hideMessage(){
    this.radioselection  = false
  }

  // closeDialouge(event:MatDialogClose){​​​​​​​
  //   // console.log(this.tooltip);
  //   // this.tooltip.show();
  //   // // this.tooltip.hide(2000);
  //   // setTimeout(() => this.tooltip.hide(500));
  //   this.newRequestForm.controls.errorMessages.disable();
  // }​​​​​​​
  fnNavigateToAlumni(){
    this.router.navigate(['app-welcome']);
  }
  fnGenerateCaptchaRefresh(){
    this.registrationService.getCaptchaService().subscribe(res=>{
      this.captchaURL=new Blob([res.body]);
      this.imageUrl=URL.createObjectURL(this.captchaURL);
      this.imageUrl=this._sanitizer.bypassSecurityTrustUrl(this.imageUrl);
       this.captchaHeader=res.headers.get('Accept-Captcha');
       //console.log("captcha headers",+ this.captchaHeader);
      
      this.newRequestForm.controls.captcha_id=this.imageUrl;
      // console.log("refersh"+this.captchaHeader);
   },
    (error)=>{
      //debugger
      console.log(error);
      console.log("No captcha header"+error);
      
   } 
    );
  }

  resetFormGroup(){
    //this.newRequestForm.controls.month=null,
  this.newRequestForm.reset({
    'month':'',
  
  })
  //   this.newRequestForm.controls.empNo
  //   this.newRequestForm.controls.emailId,
  //  this.newRequestForm.controls.firstName,
  //  this.newRequestForm.controls.lastName,
  //   this.newRequestForm.controls.mobNo,
  //   this.newRequestForm.controls.month.value=null
  //  this.newRequestForm.controls.year,
  //  this.newRequestForm.controls.captcha,
  //   // "dpo":this.newRequestForm.controls.dpo.value,
  //   this.newRequestForm.controls.privacy

  }

  ngAfterViewInit() {
    setContainerHeight();
  }

  onNavigateToTerms(){
        // your logic here.... like set the url 
    const url = 'https://www.infosys.com/terms-of-use.html';
    window.open(url, '_blank');
  }


  openDpo(dpoContent){
    return  this.modalService.open(dpoContent,{windowClass:'web_custom_modal DSA_modal-xlg', backdrop: 'static', keyboard: false})
  }
}
  
  function setContainerHeight() {
    let vh = window.innerHeight;
    let vw = window.innerWidth;
    let ch = $('#content-wrapper').outerHeight();
    let hh = $('.app-header').outerHeight();
    console.log("window "+vh+" container "+ch+" window width "+vw);
    if((ch+40+hh)>=vh && vw>=875) {
        $('.container-fluid').css({"minHeight": ch+40})
        
    }
    else {
        $('.container-fluid').css({"minHeight": vh-hh})
    }
    // else {
    //     console.log("abcd")
    //     $('.container-fluid').css({"minHeight": '100%'})
    // }
  }


  
     
    
 
  
  
  
   
  




