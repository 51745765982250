import { Injectable } from '@angular/core';
import { JavaServiceBase } from 'src/app/shared/base/javaservice-base';
import { environment } from 'src/environments/environment.prod';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { HttpClient} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {  Observable, throwError } from 'rxjs';
import { ManageOd, ManageNightStay, ManageWFH, ManageWFOCancel, ManageWFOWithdraw, ManageLeave, ManageSingleSwieAndStatusUnknown, ManageOHApply, ManageNegativeHrs } from 'src/app/shared/models/manageod.model';


@Injectable({
  providedIn: 'root'
})

export class GenerictemplateService extends JavaServiceBase {
  baseUrlStatic: string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingName;
  baseUrlDynamic: string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingUrl;
  model: string;
  constructor(private httpClient: HttpClient) {
    super();
  }



  getSectionTemplateForGenmodel(id, transactionID, paramTransID) {

    let urlSection = environment.sectionJSON;
    if (paramTransID != "") {
      transactionID = paramTransID;
    }
    urlSection = urlSection.replace("{0}", id).replace("{1}", transactionID);
    console.log(transactionID, urlSection);
   
    return this.httpClient.get(urlSection);

   
  }

  getEventData(url, type) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    console.log("getGenModelDetails service", this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getSectionTemplate(id, transactionID) {
    let urlSection = environment.sectionJSON;
    // let urlSection="http://10.89.48.11:8090/api/generictemplate/GetSection?intModuleId={0}&strQueryValue={1}"
    urlSection = urlSection.replace("{0}", id).replace("{1}", transactionID);
    console.log(transactionID, urlSection);
    return this.httpClient.get(urlSection);
    
    
  }

  getcategoryForFaq(categoryInput:string) {
    
    console.log("category");
    const url = environment.ahdcategoryfaq.replace("{0}",categoryInput);
   
    console.log("category2",url);
    return this.httpClient.get(url);
  }

  getExtending() {
    const url = './../../../assets/json/itProofAccordian.json';
    return this.httpClient.get(url);
  }

  getAccordian(url: string) {
        return this.httpClient.get(url);
  }
  getLabelView5details(url, type: string) {
    
    if (type.toLowerCase() == 'get') {
          return this.httpClient.get(url);
        }
    else {
      this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
      console.log("labelvie5 Details service", this.baseUrlDynamic, this.model);
      return this.httpClient.post(this.baseUrlDynamic, this.model);
    }
  }
  getLabelViewVertical(url) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log("labelvie5 Details service", this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getDpContent(url, apiType: string) {
 //   url = "../assets/json/genModel.json";

    if (apiType.toLowerCase() == 'get') {
      return this.httpClient.get(url);
    }
    else {
      return this.httpClient.post(url, "");
    }
  }

  getDownloadFiles(url) {
    

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log("getDownloadFiles Details service", this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  postgen2Details(url, apiType, inputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url.toString(), JSON.stringify(inputModel), apiType.toLowerCase().trim());
    console.log(this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getCardView20(url) {
    //const url='./../../../assets/json/CardViewType20.json';
    return this.httpClient.get(url);
  }
  getCardViewWorkDetails(url) {
    // const url='./../../../assets/json/workExp.json';
    return this.httpClient.get(url);
  }
  getCardView(url) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log("getGenModelDetails service", this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getCardView16Details(url) {
    // const url='assets/json/cardViewType16.json';
    return this.httpClient.get(url);

  }
  getSectionExtendedProfile() {
    const url = './../../../assets/json/genModelSectionExtendedProfile.json';
    return this.httpClient.get(url);
  }
  

  getGenericContinueTemplate(url: string, type: string, queryParam2: string = "") {
  
    console.log("getHIPContinueTemplate", url, type);
    url = url.replace("{1}", queryParam2);
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type.toLowerCase());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getLableViewDetails(url) {
    return this.httpClient.get(url);
  }

  getInboxView1Details(url: string, apiType: string, inputModel = Appconstants.empty) {
    
   if (apiType.toLowerCase() == 'get') {
    return this.httpClient.get(url);
  }
   else {
    return this.httpClient.post(url, inputModel);
   }


  }

  getEligibility(url: string, apiType: string) {
    // this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    if(apiType.toLowerCase().trim()==='get') {
      return this.httpClient.get(url);
    }
    else {
      return this.httpClient.post(url, Appconstants.empty);
    }
  }
  getTextboxViewDeatils(url: string, apiType: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    console.log('getTextboxViewDeatils');
    console.log(this.baseUrlDynamic);
    console.log(this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getCardView1Details(url: string, apiType: string, data) {
    url='./../../../assets/json/CardViewType1.json'
    return this.httpClient.get(url);
   
  }

  getCardView2Details(url: string, apiType: string) {
    if(apiType.toLowerCase().trim()==='get') {
      return this.httpClient.get(url);
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
    }
  }

  postSection(url: string, apiType: string, outputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(outputModel), apiType.toLowerCase().trim());
    console.log('postSection');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  genModelPost(url: string, apiType: string, outputModel) {
   
    return this.httpClient.post(url, outputModel);
  }
  fnGetRequestValidity(url: string, apiType: string) {
     url='./../../../assets/json/cardview.json';
    return this.httpClient.get(url);
   
  }
  fnGetRequestForType30(url: string, apiType: string) {
    // url = '../assets/json/cardview.json';
    return this.httpClient.get(url);
    

  }
  bindActionButton(url: string, apiType: string) {
    
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);

  }
 

  getDataForSelectedAction(url: string, apiType: string) {
   
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  postInboxView(url: string, apiType: string, outputModel) {
   
    return this.httpClient.post(url, outputModel);
  }

  getCardViewType5Details() {
    const url = './../../../assets/json/CardViewType5.json';
    return this.httpClient.get(url);
  }
  getCardViewType6Details(url, apiType) {
    
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  

  getSectionDetails() {
    const url = './../../../assets/json/Section.json';
    return this.httpClient.get(url);
    }
  
  getApplyJobsData(url,apiType) {
    if(apiType.toLowerCase().trim()==='get') {
      return this.httpClient.get(url);
    }
    else {
      return this.httpClient.post(url, Appconstants.empty);
    }
  }
  getQuickLinksDetails() {
    const url = './../../../assets/json/CardViewType2.json'
    return this.httpClient.get(url);
  }


  getCardDetails(url: string, apiType: string) {
 
    console.log("piechart url", url);


    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getCardDetailsForType(url) {
     url  = "../assets/json/cardview.json";
     return this.httpClient.get(url);
   

  }
  getHTMLView(url: string, apiType: string) {
    // url='./../../../assets/json/CardViewType1.json'
    // return this.httpClient.get(url);

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model, { responseType: 'text' });
  }

  cardViewLinksData(url, apiType) {
    // url='http://10.79.229.64:8080/springboot/alumni/finalJsonSPO?serviceProvider=SPO&type=IKITest'
    return this.httpClient.get(url);
    
  }
  
  submitSUForm(url: string, suBody: string) {

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, suBody, Appconstants.post);
    console.log("submitSS");
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getDetails(url: string, type: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    console.log("getdetails");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model)
      .pipe(catchError(() => throwError(new Error('error'))));
   
  }
  getFormDetails(id: string) {
    this.model = this.setModelForJavaService(Appconstants.formdetails, id, Appconstants.empty, Appconstants.empty, Appconstants.get);
    console.log("getFormDetails");
    console.log(this.baseUrlStatic, this.model);
    return this.httpClient.post(this.baseUrlStatic, this.model).pipe(catchError(() => throwError(new Error('error'))));
   
  }









  getCardViewType3Details(url: string, apiType: string) {
   // url='./../../../assets/json/CardViewType3.json';
    return this.httpClient.get(url);

  }
  getCardViewType27Details(url: string, apiType: string) {
    // url='./../../../assets/json/cardViewType27.json';
    return this.httpClient.get(url);

  }

  getCardViewType28Details(url: string, apiType: string) {
    console.log("inside service 28")

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getCardViewType4Details() {
    const url = './../../../assets/json/CardViewType4.json';
    return this.httpClient.get(url);
  }
  getCardViewType10Details() {
    const url = './../../../assets/json/CardViewType10.json';
    return this.httpClient.get(url);
  }
  getCardViewType33Details(url: string, apiType: string) {
     url='./../../../assets/json/cardviewtype33.json';
    return this.httpClient.get(url);
  }
  getCardViewType19Details(url: string, apiType: string) {
    
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    //  console.log("model19 ",this.model); 
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getPremiumPlan() {
    const url = './../../../assets/json/CardViewType7.json';
    return this.httpClient.get(url);
  }
  getCardViewType11Details(url: string, apiType: string) {
    
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getCardViewType12Details() {
    const url = './../../../assets/json/CardViewType12.json';
    return this.httpClient.get(url);
  }
  getCardViewType8Details() {
    const url = './../../../assets/json/CardViewType8.json';
    return this.httpClient.get(url);
  }
  getCardViewType9Details() {
    const url = './../../../assets/json/CardViewType9.json';
    return this.httpClient.get(url);
  }
  getCardViewType13Details(url: string, apiType: string) {
    // const url='./../../../assets/json/CardViewType13.json';
    // return this.httpClient.get(url);
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  

  getCardDetailsFor23() {
    //const url='http://10.82.5.132:8280/api/EmployeeLoanDetailsIL/GetAllEmployeeEligibleLoans?IsMobile=t';
    let url = "./../../../../../../assets/json/cardViewType27.json";
    return this.httpClient.get(url);
    
  }
  loadRecommendations() {
    const url = './../../../assets/json/Recommendations.json';
    return this.httpClient.get(url);
  }
  getGenModelDetails(url, apiType, inputModel, heading) {
    console.log("heading", heading);
  

    return this.httpClient.get(url);
   
  }
  getGenModelDetailsChild(url, apiType, inputModel, heading) {
    return this.httpClient.post(url,inputModel);
    
  }
  getGenModelDetailsHard(url) {

    
      return this.httpClient.get(url); 
    
  }

  getGenModelDetails2() {
   
    let url = './../../../assets/json/GenModelSmartCard.json';
    return this.httpClient.get(url);
  }
  takeAction(url: string, apiType: string, selectedId: string[]) {
    let actionModel: { slNo: string[] } = { slNo: [] };
    actionModel = {
      slNo: selectedId
    };

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(actionModel), apiType.toLowerCase().trim());
    console.log("takeaction service", this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  loadViewDetails(transactionId) {
    console.log("viewdetails", transactionId);

    const baseurl = '';
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, Appconstants.empty, Appconstants.get);
    console.log('smssssss');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getCardView17Details(url) {
    return this.httpClient.get(url);

  }

  getDetailsForPieCharts() {

   
    const dummyUrl: string = './../../../assets/json/piechart.json';
    return this.httpClient.get(dummyUrl);

  }

  getCardViewType18Details() {
    const dummyUrl: string = './../../../assets/json/CardViewType18.json';
    return this.httpClient.get(dummyUrl);
  }
  getInboxViewDetails() {
    const url = "./../../../assets/json/InboxView.json";
    return this.httpClient.get(url);
  }
  downloadForm(url, apiType, inputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url.toString(), JSON.stringify(inputModel), apiType);
    console.log(this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  fnPostCertificates(url, apiType, inputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url.toString(), JSON.stringify(inputModel), apiType.toLowerCase().trim());
    console.log('fnPostCertificates');
    console.log(this.baseUrlDynamic)
    console.log(this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  async getDetailsForDropDown(url, type = "get") {
    return this.httpClient.get(url)
   
  }

  async getDetailsForDropDownMultiple(url) {
  

    url = '/assets/json/optionsforVlookupMultiple.json';
    return this.httpClient.get(url).toPromise();
  }
  directLinkAction(url: string, apiType: string) {
    // url='./../../../assets/json/CCC/eligibility.json'
    // return this.httpClient.get(url);

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getInboxViewFortype62(url, inputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(inputModel), Appconstants.post);
    console.log("getInboxViewFortype62");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
    //  url='/assets/json/VLookupInbox62.json';
    //  return this.httpClient.get(url);
  }

  getDataForPreview(baseurl) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, Appconstants.empty, Appconstants.get);
    console.log('getDataForPreview');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  deleteDetailsForInbox(url: string, type) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url.trim(), Appconstants.empty, type);
    console.log('getDataForPreview');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getLableViewData() {
    const url = './../../../assets/json/LabelViewTypeWithMultipleFields.json';
    return this.httpClient.get(url);
  }
  deletePriorStay(url: string, apiType: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getFieldActionData(url, id) {
    //   const url='./../../../assets/json/LabelViewTypeWithMultipleFields.json';
    // return this.httpClient.get(url);
    let link = url.replace('{0}', id);
    const baseurl = link;
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  postByteArray(url: string, modelForUpload) {
    console.log("postByteArray", modelForUpload, url);

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(modelForUpload), Appconstants.post);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  DownloadEdocketFile(url, apiType) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model, { responseType: 'text' });
  }
  DownloadgetEdocketFile(url, apiType) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);//, {responseType: 'text'});
  }
  DownloadFile(url, apiType) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  async getDetailsForDD(url: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log(this.baseUrlDynamic, this.model, url);
    return await this.httpClient.post(this.baseUrlDynamic, this.model).toPromise();
  }
  getCustomcardviewJson() {
    let urlSection: string = "";
    // urlSection  = "/assets/json/cardview/EFHIPEligiblePlans.json"; 
    // urlSection  = "/assets/json/cardview/EFHI2.json"; 
    urlSection = "/assets/json/cardview/EFHIPEligiblePlans2.json";
    return this.httpClient.get(urlSection);
  }
  getEhipJsonDetails() {
    console.log('getEhipJsonDetails')
    let urlSection: string = "";
    urlSection = "/assets/json/cardview/EFHIPChoosePlanVer1.0.json";
    return this.httpClient.get(urlSection);
    

  }
  getOdDetails(url: string, apiType: string) {
   
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);



  }

  getAllActivities(baseurl, apiType) {
  
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, Appconstants.empty, apiType);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  fnPostActivities(baseurl, apiType) {
 
    console.log('postActivities', baseurl);

    if (apiType != undefined && apiType.toLowerCase() == Appconstants.get) {
      this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, Appconstants.empty, apiType.toLowerCase().trim());
    }
    else {
      this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, null, apiType.toLowerCase().trim());

    }
    console.log("getGenModelDetails service", baseurl, this.model, apiType);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  fnPostBulkApproval(baseurl, apiType, inputModel) {

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseurl, inputModel, apiType.toLowerCase().trim());
    console.log("getGenModelDetails service", baseurl, this.model, apiType);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  fnGetHipPlandetails() {
    let url = environment.ehipPlaDetailsUrl;
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, 'get');
    console.log('getDataForPreview');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getFilterData(baseurl) {
    // baseurl  = "../assets/json/filter.json";
    return this.httpClient.get(baseurl);
   
  }

  fnPostFilterData(url, apiType, inputModel) {
    if (apiType.toLowerCase() == Appconstants.get) {
      return this.httpClient.get(url);
    }
    else {
      return this.httpClient.post(url, inputModel);
    }
    
  }



  async getDependantDetails(url) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log("getDetailsForDropDown");
    console.log(this.baseUrlDynamic, this.model);
    return await this.httpClient.post(this.baseUrlDynamic, this.model).toPromise();
  }

  deleteDashboardCard(url: string, apiType: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getLableView5Data() {
    let baseurl = "/assets/json/labelview/LableViewVerical.json";
    return this.httpClient.get(baseurl);
  }
  getTabViews(url: string, type: string) {
    // let baseurl  = "/assets/json/tabviewalg.json";
    // return this.httpClient.get(baseurl);
    if (type.toLowerCase() == Appconstants.get) {
      return this.httpClient.get(url);
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    console.log('tabss');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
    }
  }

  getActionDetails(url, type) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    console.log('tabss');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
 

  getCardviewReach(url) {

    // const url='../assets/json/REACH/ConcernedEmployeeDetails.json';
    // return this.httpClient.get(url);

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log('getCardviewReach');
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }



  getFileuploadGrid(url: string, apiType: string, inputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(inputModel), apiType.toLowerCase().trim());
    console.log("getfileUploadgrid", this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);

  }
  editInboxView(url: string, apiType: string, inputModel) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(inputModel), apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getInboxVieworVlookup(url) {
    console.log(url);
    // url='/assets/json/vlookup63/inbox63_1.json';
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
    //  return this.httpClient.get(url);
  }

  getInboxVieworVlookupChild(url) {
    console.log(url);
    url = '/assets/json/vlookup63/inbox63_2.json';
    return this.httpClient.get(url);
  }


  getGenModelForVlookup62(url) {
   
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log('getGenModelForVlookup62', this.model);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getHTMLContent(url: string,type) {
     // url='/assets/json/htmlcontent';

      if (type.toLowerCase() == 'get') {
      return this.httpClient.get(url, { responseType: 'text' });
    }
    else {
      return this.httpClient.post(url, null, { responseType: 'text' });
    }

  }

  getHTMLContentLoans(url: string) {
    //const baseurl = 'http://10.82.5.132:8280/api/EmployeeLoanDetailsIL/GetDashboardContent?IsMobile=t';
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log('loans', this.model);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }


  getFilter(url, apiType) {
    
    return this.httpClient.get(url);

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  applyFilter(url: string, apiType: string, inputModel) {

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(inputModel), apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getInboxViewForManage(url: string, apiType: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, apiType.toLowerCase().trim());
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  takeActionForManage(url: string, name: string, transactionId: string, action: string, remarks: string, approverMailId: string,
    selectedRow: object) {
    console.log(name);
    let manageModel: string;
    manageModel = this.setModelForManage(transactionId, name, action, remarks, approverMailId, selectedRow);
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, manageModel, Appconstants.post);
    console.log(this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  setModelForManage(transactionId: string, name: string, action: string, remarks: string, approverMailId: string,
    selectedRow: object): string {
    if (name.toLowerCase() === 'manage od'.toLowerCase()) {
      let modelForManage: ManageOd = { intReqNo: 0, loggedEmpNo: "", reqStatus: "", sourceType: "" };
      modelForManage.intReqNo = Number(transactionId);
      return JSON.stringify(modelForManage);
    }
    else if (name.toLowerCase() === 'manage nightstay'.toLowerCase()) {
      let modelForManage: ManageNightStay = {
        reqNumber: 0,
        empNo: "",
        nightRequestDate: "",
        outTime: "",
        remarks: "",
        approver: "",
        status: "",
        fromDate: "",
        toDate: "",
        loggedEmpNo: "",
        approverRemarks: "",
        newApproverID: "",
        newApproverEmpNo: "",
        sourceType: ""
      };
      modelForManage.reqNumber = Number(transactionId);
      return JSON.stringify(modelForManage);
    }
    else if (name.toLowerCase() === 'manage wfh'.toLowerCase()) {
      let modelForManage: ManageWFH = {
        intReqNo: 0,
        apprEmpNo: "",
        statusTobeUpdate: "",
        sourceSystem: ""
      };
      modelForManage.intReqNo = Number(transactionId);
      return JSON.stringify(modelForManage);
    }
    else if (name.toLowerCase() === 'Manage WFH'.toLowerCase()) {
      let modelForManage: ManageWFH = {
        intReqNo: 0,
        apprEmpNo: "",
        statusTobeUpdate: "",
        sourceSystem: ""
      };
      modelForManage.intReqNo = Number(transactionId);
      return JSON.stringify(modelForManage);
    }
    else if (name.toLowerCase() === 'manage wfo'.toLowerCase()) {
      if (action === 'cancel') {
        let modelForManage: ManageWFOCancel = {
          intReqNo: 0,
          empNo: "",
          sourceSystem: ""
        };
        modelForManage.intReqNo = Number(transactionId);
        return JSON.stringify(modelForManage);
      }
      else if (action === 'withdraw') {
        let modelForManage: ManageWFOWithdraw = {
          intReqNo: 0,
          loggedEmpNo: "",
          status: "",
          sourceType: ""
        };
        modelForManage.intReqNo = Number(transactionId);
        return JSON.stringify(modelForManage);
      }
    }
    else if (name.toLowerCase() === 'manage leave'.toLowerCase()) {
      let modelForManage: ManageLeave = {
        approverMailId: approverMailId,
        formId: 0,
        remarks: remarks,
        action: "",
        flgLeavePlanned: "",
        applicantMailId: ""
      };
      modelForManage.formId = Number(transactionId);
      return JSON.stringify(modelForManage);
    }
    else if (name.toLowerCase() === 'manage compoff'.toLowerCase()) {
      return Appconstants.empty;
    }
    else if (name.toLowerCase() === 'Manage Single Swipe'.toLowerCase()
      || name.toLowerCase() === 'Manage Status Unknown'.toLowerCase()) {
      let modelForManage: ManageSingleSwieAndStatusUnknown = {
        requestDate: transactionId,
        empMailID: "",
        sourceType: ""
      };
      return JSON.stringify(modelForManage);
    }
    else if (name.toLowerCase() == 'Apply optional holiday'.toLowerCase()) {
      let modelForApplyOH: ManageOHApply = {
        applicantMailId: '',
        ohDate: selectedRow['oneRow']['item0']
      }
      return JSON.stringify(modelForApplyOH);
    }
    else if (name.toLowerCase() === 'Manage Negative Hours'.toLowerCase()) {
      let modelForManage: ManageNegativeHrs = {
        negHrs: selectedRow['oneRow']['item0']
      };

      return JSON.stringify(modelForManage);
    }
  }

  getManageData(url: string, name: string, apiType: string) {
    var today = new Date();
    console.log(today);
    let dd: number = today.getDate();
    let mm: number = today.getMonth();
    var year = today.getFullYear();
    let date: string = '';
    let currMonth: string = '';
    let prevMonth: string = '';
    let fromdate: string = ''
    let toDate: string = ''
    let fromYear: number;
    //fromYear = year;/* Commented for SonarQube */

    console.log("getmanagedata");
    if (dd < 10) {
      date = '0' + dd;
    }
    else {
      date = dd.toString();
    }

    if (mm < 10) {
      currMonth = '0' + (mm + 1);
    }
    else {
      currMonth = (mm + 1).toString();
    }

    if (mm < 10 && mm != 0) {
      prevMonth = '0' + mm
      fromYear = year - 1;
    }
    else if (mm === 0) {
      prevMonth = '12'
      fromYear = year - 2;
    }
    else {
      prevMonth = mm.toString();
      fromYear = year - 1;
    }

    if (Number(currMonth) > 10) {
      currMonth = '0' + (Number(currMonth) % 10).toString();
      year = year + 1;
    }
    else {
      if ((Number(currMonth) + 6) >= 10) {
        currMonth = '0' + (Number(currMonth) % 10).toString();
        year = year + 1;
      }
      else {
        currMonth = '0' + (Number(currMonth) + 6).toString();
      }
    }

    fromdate = prevMonth + '-' + date + '-' + fromYear;
    toDate = currMonth + '-' + date + '-' + year;
    let queryString = name.toLowerCase() === 'manage leave' ? fromdate + ',' + toDate : '';
    console.log("getmanageDate");
    console.log(queryString);
    this.model = this.setModelForJavaService(Appconstants.empty, queryString, url, Appconstants.empty, apiType);
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getLeaveDetails() {
    let envUrl: string = '';
    if (environment.type.toLowerCase() == 'prod') {
      envUrl = './assets/json/leaveprod.json';
    }
    else {
      envUrl = './assets/json/leave.json';
    }
    console.log("getLeaveDetails", envUrl);
    return this.httpClient.get(envUrl);
  }

  getCompoffDetails() {

    let envUrl: string = '';
    if (environment.type.toLowerCase() == 'prod') {
      envUrl = './assets/json/compoffprod.json';
    }
    else {
      envUrl = './assets/json/compoff.json';
    }
    return this.httpClient.get(envUrl);
  }


  getAdvanceLeaveDetails() {
    let url: string = '';
    url = './assets/json/advanceleave.json';
    return this.httpClient.get(url);
  }

  getBalance(url, type) {
    // url='/assets/json/inboxview/filter.json';
    // return this.httpClient.get(url);

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getWorkFlow(url, type) {
    
    return this.httpClient.get(url);

  }

  deleteFile(url, type) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }


  getFooterData(url, type) {
    console.log(url, type);
 
    return this.httpClient.get(url);
  }

  eventdata(url, type) {
    //  url='./../../../assets/json/events.json'
    return this.httpClient.get(url);
    
  }

  infyFoundationData(url, type) {
   
    return this.httpClient.get(url);
    
  }
  getCardDetailsWithCarousel(url, type) {
   
    return this.httpClient.get(url);
   
  }
  getInfyStoriesData(url, type) {
   
    return this.httpClient.get(url);
   
  }
  getCardview35Data(url, type) {
    // url='../assets/json/cardviewtype35.json'
    return this.httpClient.get(url);
   

  }
  getLabelViewType7Data(url, type) {
    //     url='../assets/json/labelviewtype7.json'
    return this.httpClient.get(url);
    

  }
  getLabelView7ButtonAction(url, type) {
    //  url='../assets/json/labelviewtype7.json'
    return this.httpClient.get(url);
    

  }


  getProfileImage(url, type) {
    // url='/assets/json/genModel.json'
    return this.httpClient.get(url);
    // this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, type);
    // return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_blank";
    mapForm.method = "POST";
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  spotlightArchives(url, type: string) {

    if(type.toLowerCase() == 'get'){
       return this.httpClient.get(url);
    }
    else{
      return this.httpClient.post(url, Appconstants.empty);
    }
  }
  alumniSpotlightDetails(url, type: string) {
    if(type.toLowerCase() == 'get'){
      return this.httpClient.get(url);
  }
  else{
    return this.httpClient.post(url, Appconstants.empty);
  }
  }

  postDpConsent(url, type, requestBody) {
   // url = '/assets/json/respgen.json';
 //   return this.httpClient.get(url);
    if(type.toLowerCase() == 'get'){
      return this.httpClient.get(url);
  }
  else{
    return this.httpClient.post(url, requestBody);
  }
  }
  reset(usermailid):Observable<String>{
    // let url=`${environment.resetpassword}?username=${usermailid}`
    let url=`${environment.resetpassword}`
      console.log(url);
      console.log("Before Call");
      // result= this.httpClient.post(url,'',{params: new HttpParams().set('username', usermailid)});
      let result  = this.httpClient.post(url,usermailid,{ responseType:'text'})
      console.log("After call"+result)
      return result;
    }
    changeofmailid(input){
      let url=`${environment.changeofmailidservice}`;
        console.log(url);
        let result:any;
        console.log("Before Call");
        result= this.httpClient.post(url,input);
        console.log("After call"+result);
        return result;
      }
    updatemailid(otpNumber,input)
    {
      let url=`${environment.changeofmailidservice}?otpNumber=${otpNumber}`;
        console.log(url);
        let result:any;
        console.log("Before Call");
        result= this.httpClient.post(url,input);
        console.log("After call"+result);
        return result;
    }
  
    checkInterestinJobs(url,requestBody) {
      //  url='../assets/json/image.json'
      return this.httpClient.post(url, requestBody);
    }

    fetchJson(url) {
    //  url='assets/json/genModel.json';
    //  url='/assets/json/Contacts.json'
      return this.httpClient.get(url);
      }

      
  postMethodCall(url: string, modelForEmailAPI){
   // url='http://10.89.117.67:8080/springboot/alumni/EdocketCats';
    console.log("service " +url)
    console.log(modelForEmailAPI)
    // this.model =this.setModelForJavaService(Appconstants.empty,Appconstants.empty,url,JSON.stringify(modelForUpload),Appconstants.post);
     return this.httpClient.post(url,modelForEmailAPI);
   }

   getSectionTemplateForEmployeeNo(id,transactionID,inputModel){
    let urlSection = environment.sectionJSON;
   
    urlSection = urlSection.replace("{0}", id).replace("{1}", transactionID);
    console.log(transactionID, urlSection);
    return this.httpClient.post(urlSection,inputModel)

  }

}

