import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
@Input() placeholder: string;
blnLoad: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.blnLoad = true;
  }

}
