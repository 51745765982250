export const Appconstants = {
    AppDownJSON:null,
    loginUrl:'https://login.microsoftonline.com/infosystechnologies.onmicrosoft.com/oauth2/authorize?client_id=$clientid&response_type=code&scope=openid&response_mode=query&redirect_uri=$redirecturi&domain_hint=infosys.com&resource=$resource&state=12345&nonce=678910',
    // loginUrl:'https://login.microsoftonline.com/isepmpoc.onmicrosoft.com/oauth2/authorize?client_id=$clientid&response_type=code&scope=openid&response_mode=query&redirect_uri=$redirecturi&domain_hint=infosys.com&resource=$resource&state=12345&nonce=678910',
    accessToken: 'accesstoken',
    input: 'input',
    date: 'date',
    select: 'select',
    textarea: 'textarea',
    applyLeave: 'Apply Leave',
    applyLeaveId: 1,
    wfhId: "10",
    inputId: 0,
    selectId: 11,
    textareaId: 1,
    dateId: 15,
    dateId2: 16,
    checkBoxId: 5,
    type: '11',
    empNo: '684450',
    empty: '',
    isRemoteConnection: 1,
    blackBerryUser: true,
    company: 'INFSYS',
    address: 'Test',
    zero: 0,
    one: 1,
    two: 2,
    minusOne: -1,
    rdTapIT: 'rdTapIT',
    rdPeople: 'rdPeople',
    rdProject: 'rdProject',
    rdLocation: 'rdLocation',
    peopleSearch: '100',
    projectSearch: 'PRJECT',
    locationSearch: 'LCTN',
    searchData: 'searchData',
    equalTo: '=',
    cardViewId: '5',
    groupCardViewId: '28',
    leaveComponmet:'2',
    holidayCalenderComponmet:'1',
    dynamicFormComponentId: 'T',
    peopleSearchComponentId: '100',
    projectSearchComponentId: 'PRJECT',
    locationSearchComponentId: 'LCTN',
    faqDynamic: '1',
    accessTokenNull: null,
    accessTokenSet: 'set',
    unAuthorized: 401,
    tapIt: '/tapit',
    ttd: '/ttd',
    info: '/info',
    circle: '/circle',
    ignoreCase: "i",
    faqRoutingPath: "/app-faq",
    faqRoutingNavigate: "faq/app-faq",
    tapitNavigate: "/tapit",
    homeRouting: "/app-home",
    hideSearchArea: "hide",
    showSearchArea: "show",
    flipHide: "sp2_flipcard hide",
    flipShow: "back show",
    appendString: "_",
    peopleSearchText: 'People search',
    projectSearchText: 'Project search',
    txtEmpNo: 'EmpNo',
    searchedEmpNO: "{3}",
    locationTitle: 'Location search',
    noEmployeeNumber: 'Kindly enter employee number/name to search',
    noData: 'Data Not Available',
    emptyData: null,
    typeAhead: "tapitemphub/api/TapIT/TypeAhead/both",
    peopleSearchUrl: "ids/api/EmployeeDetails/GetFilteredEmployeeDetailsFAST?txtEmpNameOrtxtEmpno=",
    projectSearchUrl: "hivesrchres/api/HiveSearchResult/FnGetKeywordSearchResultsHiveMobile?strSearchKey=",
    projectKeywordSearchUrl: "&batchCount=0&FilterValue=null",
    locationSearchUrl: "ids/api/EmployeeDetails/GetOfficesSearch?txtestname=",
    inbayInfoItemsUrl: "inbaytransactions/InformationItem",
    actionDetailsUrl: "inbaytransactions/ActionItemForWeb",
    approverDetailsUrl: "inbaytransactions/ApprovalItemForWeb",
    formDetailsUrl: "tapitemphub/api/TapIT/",
    approvalRemarks: "{1}",
    approvalId: "{2}",
    undefined: undefined,
    tapItQuery: "Q",
    attendanceHoursQuarterly: 54,
    attendanceHoursYearly: 28,
    fields: 'fields',
    dependentDetailsId: '1160',
    true: true,
    empHubProfilePicUrl: 'tapitemphub/api/TapIT/FetchProfilePicture',
    wfhPath: 'assets/json/wfhDetails.json',
    dependentDetailsUrl: 'api/EmployeeDependentInfo/GetDependentDetails?IsMobile=true',
    logout: 'app-logout',
    accessTokenNullStr: "null",
    success: "Success",    
    // accessTokenUrl:"http://10.177.41.102/access-token?code=",
    // accessTokenUrl: "http://10.66.22.130:4000/access-token",
    inbayDeltionUrl: 'inbaytransactions/ActionItemForWeb',
    peopleSearchUrlSwagger: "api/EmployeeDetails/GetFilteredEmployeeDetailsFAST?txtEmpNameOrtxtEmpno=",
    projectSearchUrlSwagger: "api/HiveSearchResult/FnGetKeywordSearchResultsHiveMobile?strSearchKey=",
    locationSearchUrlSwagger: "api/EmployeeDetails/GetOfficesSearch?txtestname=",
    typeAheadSwagger: 'api/TapIT/GetTypeAhead?Keyword=both',
    formDetailsUrlSwagger: 'api/TapIT/GetSearchActivityUrl?ID=',
    empHubProfilePicUrlSwagger: 'api/TapIT/FetchProfilePicture',
    inbayDeltionUrlSwagger: 'InbayInformation/DeleteItem/',
    postLeaveswagger:'api/UserActions/PostApplyEarnedLeave',  
    // getleaveSwagegr:'http://10.82.5.132:8564/swagger/index.html', 
    
    resource: 'https://itgatewaytst.infosys.com/',
    clientId: 'feacf2e9-234e-4eef-85fe-534b0551bdd3',
    wallRouting: '/app-wall/app-home',
    errorRouting: '/app-error',
    loginRouting:'/app-login',
    znch:'/zh-cn',
    typeAheadSwaggerLauncher: 'api/TapIT/GetTapitLaunchersDetails?strSelectedFunction',
    launches:'Launches',
    work: '/app-wall/work',
    ahd: '/app-wall/ahd',
    approved: 'approved',
    rejected: 'rejected',
    redirect: 'redirected',
    forward: 'forwarded',
    reportAProblem:'http://isvgenwt01/ReportProblemV2/Main.aspx?AppName=EmpHub',
    batchCount : ",0,",
    dateString: 'date',
    monthString: 'month',
    yearString: 'year',
    genModelPath: 'assets/json/genModel.json',
    genModelId: '100000',
    routingLogin: 'login',
    routingWall: 'wall',
    get: 'get',
    post: 'post',
    both : 'both',
    inbayinfo : 'inbayinfo',    
    inbayaction : 'inbayaction',
    inbayapprover : 'inbayapprover',
    peoplesearch : 'peoplesearch',
    projectsearch : 'projectsearch',
    locationsearch : 'locationsearch',
    profilepicservice : 'profilepicservice',
    valuefortypeahead : 'valuefortypeahead',
    formdetails : 'formdetails',
    dependentdetailsurl : 'dependentdetailsurl',
    tapitswaggerlauncher : 'tapitswaggerlauncher',
    projectcodedetails : 'projectcodedetails',
    inbaydelete : 'inbaydelete'  ,
    yearly: 'Yearly',
    accessTokenBaseUrl: "access-token",
    javaServiceUsingName: 'ehub/v1/doServiceCallusingName/',
    javaServiceUsingUrl: 'ehub/v1/doServiceCallusingUrl/',
    chatbotEndPoint: '#/chat-bot?bot=employeebot',
    quarterly: 'Quaterly',
    monthly: 'Monthly',
    chatBotclick:'ChatBotclick',
    chatBotComponent:'ChatBotComponent',
    chatBotScroll:'ChatBotScroll',
    chatBotKeypress:'ChatBotKeypress',
    chatBot:'ChatBot',
    errorclick:'Errorclick',
errorComponent:'ErrorComponent',
errorScroll:'ErrorScroll',
errorKeypress:'ErrorKeypress',
error:'Error',
footerclick:'Footerclick',
footerComponent:'FooterComponent',
footerScroll:'FooterScroll',
footerKeypress:'FooterKeypress',
footer:'Footer',
headerclick:'Headerclick',
headerComponent:'HeaderComponent',
headerScroll:'HeaderScroll',
headerKeypress:'HeaderKeypress',
header:'Header',
homeclick:'Homeclick',
homeComponent:'HomeComponent',
homeScroll:'HomeScroll',
homeKeypress:'HomeKeypress',
home:'Home',
oneClickAHDclick:'OneClickAHDclick',
oneClickAHDComponent:'OneClickAHDComponent',
oneClickAHDScroll:'OneClickAHDScroll',
oneClickAHDKeypress:'OneClickAHDKeypress',
oneClickAHD:'OneClickAHD',
workclick:'Workclick',
workComponent:'WorkComponent',
workScroll:'WorkScroll',
workKeypress:'WorkKeypress',
workid:'Work',
navbarclick:'Navbarclick',
navbarComponent:'NavbarComponent',
navbarScroll:'NavbarScroll',
navbarKeypress:'NavbarKeypress',
navbar:'Navbar',
detailedViewclick:'DetailedViewclick',
detailedViewComponent:'DetailedViewComponent',
detailedViewScroll:'DetailedViewScroll',
detailedViewKeypress:'DetailedViewKeypress',
detailedView:'DetailedView',
emptyClick:'EmptyClick',
emptyScroll:'EmptyScroll',
emptyKeypress:'EmptyKeypress',
emptyComponent:'EmptyComponent',
genmodelclick:'Genmodelclick',
genmodelComponent:'GenmodelComponent',
genmodelScroll:'GenmodelScroll',
genmodelKeypress:'GenmodelKeypress',
genmodel:'Genmodel',
groupCardViewclick:'GroupCardViewclick',
groupCardViewComponent:'GroupCardViewComponent',
groupCardViewScroll:'GroupCardViewScroll',
groupCardViewKeypress:'GroupCardViewKeypress',
groupCardView:'GroupCardView',
locationSearchViewclick:'LocationSearchViewclick',
locationSearchViewComponent:'LocationSearchViewComponent',
locationSearchViewScroll:'LocationSearchViewScroll',
locationSearchViewKeypress:'LocationSearchViewKeypress',
locationSearchView:'LocationSearchView',
peopleSearchclick:'PeopleSearchclick',
peopleSearchComponent:'PeopleSearchComponent',
peopleSearchScroll:'PeopleSearchScroll',
peopleSearchKeypress:'PeopleSearchKeypress',
peopleSearchid:'PeopleSearch',
projectDetails:'ProjectDetails',
projectDetailsComponent:'ProjectDetailsComponent',
projectDetailsScroll:'',
projectDetailsKeypress:'ProjectDetailsKeypress',
tableView:'TableView',
tableViewclick:'TableViewclick',
tableViewComponent:'TableViewComponent',
tableViewScroll:'TableViewScrol',
tableViewKeypress:'TableViewKeypress',
dynamicFormclick:'DynamicFormclick',
dynamicFormComponent:'DynamicFormComponent',
dynamicFormScroll:'',
dynamicFormKeypress:'DynamicFormKeypress',
dynamicForm:'DynamicForm',
tapitclick:'Tapitclick',
tapitComponent:'TapitComponent',
tapitScroll:'TapitScroll',
tapitKeypress:'TapitKeypress',
wallclick:'wallclick',
wallcomponent:'wallcomponent',
wallcompKeypress:'WallcompKeypress',
wallcompScroll:'WallcompScroll',
wall:'Wall',
tapit:'Tapit',
people: 'People',
contactSearchclick:'ContactSearchclick',
contactSearchComponent:'ContactSearchComponent',
contactSearchScroll:'ContactSearchScroll',
contactSearchKeypress:'ContactSearchKeypress',
contactSearch:'ContactSearch',
infoitemview:'Infoitemview',
infoitemviewComponent:'InfoitemviewComponent',
infoitemviewScroll:'InfoitemviewScroll',
infoitemviewKeypress:'InfoitemviewKeypress',
infoitemviewid:'Infoitemviewid',
todoListclick:'TodoListclick',
todoListComponent:'TodoListComponent',
todoListScroll:'TodoListScroll',
todoList:'TodoList',
todoListKeypress:'TodoListKeypress',
myTeamclick:'MyTeamclick',
myTeamScroll:'MyTeamScroll',
myTeamKeypress:'MyTeamKeypress',
myTeam:'MyTeam',
myTeamComponentid:'MyTeamComponent',
empservviceclick:'Empservviceclick',
empservviceScroll:'EmpservviceScroll',
empservviceKeypress:'EmpservviceKeypress',
empservvice:'Empservvice',
empservviceComponentid:'EmpservviceComponent',
viewallservviceclick:'Viewallservviceclick',
viewallservviceScroll:'ViewallservviceScroll',
viewallservviceKeypress:'ViewallservviceKeypress',
viewallservvice:'Viewall',
viewallservviceComponentid:'ViewallservviceComponent',
GAP:'GAP',
app:'APP',
ver:1.0,
localTax:'26503',
domesticTravel:'26527',
internationalTravel:'26516',
accommodation:'26499',
glomoLandingConfirmation:'4102',
profilepage:'/app-wall/profile/default',
profilepagedefault:'/app-wall/profile/',
viewallservices  : 'viewallservices',
imgAssets : 'assets/images/',
profileservice:'ProfileHarmonyService_url',
profileAttendanceservice:'AttendanceForProfile_url',
queryParamForPrfoile:'true',
queryParamForAttendance:'Yearly,T',
timeId: 17,
remarks:"",
labelId: 14,
radioId:18,
searchInputButton:25,
note:12,
interceptor: "Interceptor",
postApplyEarnedLeave : "PostApplyEarnedLeave",
postApplyCompOffLeave : "PostApplyCompOffLeave",
postApplyLeaveWithoutPay : "PostApplyLOPLeave",
postApplyCompOffPreApproval : "PostApplyCompOffPreApproval",
postApplyAdvanceLeaves : "PostApplyAdvanceLeaves",
aplLeave : "Apply Leave",
aplCompOff : "Create CompOff",
aplAdvLeave : "Apply Advance Leave",
applyEL : "Earned Leave",
applyLOP : "Leave Without Pay",
applyLeaveComp : "Comp Off",
aplWFH: 'Apply WFH',
applyLateStay : "apply latestay",
multiTravelCard:"multi",
aplOD: 'Apply OD',
invalidDateRange: 'To Date should be greater than From Date',
invalidDuration: 'Please limit the date range to 31 days!',
noSSRecords: "You do not have any single swipe records",
horizontalTile: "horizontalTile",
largeTile: "largeTile",
verticalTile: "verticalTile",
feedbackPage:'/app-wall/feedback',
dataprivacyPage:'/app-wall/dataPrivacy',
shareanideaPage:'/app-wall/shareanidea',
BankDetails:'/app-wall/BankDetails',
publishingsBlog:'/app-wall/publishings',
PresentAddress:"PRESENT",
PermanentAddress:"PERMANENT",
EmergencyAddress:"EMERGENCY",
OnsiteWorkLocationAdd:"OnsiteWorkLocation",
OnsiteResidentialAdd:"OnsiteResidential",
OnsiteEmergencyAdd:"OnsiteEmergency",
OnsiteWorkLocation:"Work Location",
OnsiteResidential:"Residential",
OnsiteEmergency:"Emergency Contact",
workLocation:"Work Location Address Details",
residential:"Residential Address Details",
emergencyContact:"Emergency Address Details",
OfficialKey:"official",
PersonalKey:"personal",
EducationKey:"education",
DataAvailable:"Yes",
popularIdea:"popular",
mostRecentIdea:"mostrecent",
sparshcontent: "sparshcontent",
peopleSearchList: "people",
noRSRecords: "You dont have any Status unknown record to regualrize",
postApplySuppLeave : "PostApplySupplementaryLeave",
applySuppLeave : "Supplementary Holiday",
edocketStatus: 'Approved',
fifteen: 15,
eDocketNA: 'NA',
eDocketDlimiter: '.',
pathCAG: '/assets/images/profile-documents.png',
pathCNB: '/assets/images/cag-documents.png',
pathER: '/assets/images/cag-documents.png',
liked:1,
isMobile:false,
mycirclesub:'/app-wall/submycircle',
postApplySickLeave : "PostApplySickLeave",
applySickLeave : "Sick Leave",
mycircle:'mycircleurl',
sparshrecommendations:'sparshrecommendations',
getcomments:'getcomments',
postcomments:'postcomments',
likecomments:'likecomments',
sparsharticle:'sparsharticle',
dphistory:'dphistory',
dpwithdrawal:'dpconsentwithdrawal',
isPageLoad: true,
eDocket:'/app-wall/app-profile/app-edocket',
eDocketConsent: 'eDocketConsent',
editUniqueId:'/app-wall/app-profile/app-edit-unique-ids',

//SearchConstants

appendStringForMail:"@ad.infosys.com",
appendStringForAvailability:"@ad.infosys.com?startDate=",
userDataUrl:"/search/indexed?contentSource=telephone&pageSize=10&source=Mobile&startIndex=0&queryText=",
userImageUrl:"/users/profilepicture/",
userAvailabilityUrl:"/users/availability/",
userSkillsUrl:"/search/indexed?contentSource=allskillsfromgraph&pageSize=10&source=Mobile&startIndex=0&queryText=",
userAwardsUrl:"/search/indexed?contentSource=afewinners&pageSize=10&source=Mobile&startIndex=0&queryText=",
userProjectsUrl:"/search/indexed?contentSource=projectdetails&pageSize=10&source=Mobile&startIndex=0&queryText=",
userBlogsUrl:"/search/indexed?contentSource=myblogs&pageSize=10&source=Mobile&startIndex=0&queryText=postauthorname:",
userWhitePapersUrl:"/search/indexed?contentSource=pubportal&pageSize=50&source=Mobile&startIndex=0&queryText=pubauthormailid:",
userWhitePapersUrl2:"/search/indexed?contentSource=infosysresults&pageSize=10&source=Mobile&startIndex=0&queryText=document.reference:white-papers AND ",
userBlogsUrl2:"/search/indexed?contentSource=webapps&pageSize=10&source=Mobile&startIndex=0&queryText=document.reference:sparshv2 AND document.reference:Blogs AND author:",
similarProfileUrl:"/search/indexed?contentSource=allskillsfromgraph&pageSize=10&source=Mobile&startIndex=0&queryText=Skills:",
getManagerUrl:"/users/manager/",
getDirectReportsUrl:"/users/directReports/",
typeAheadUrl:"/search/typeahead?queryText=",
advMessage : "You are not eligible for advance leave",
remarksBulkReject: 'reject',
notEnabled: 'This feature is currently not enabled for your company in Infy Me.',
errNoDocsCAGandCNB: "You don't have any document for the selected year.",
errNoDocsERAndVISA: "You don't have any document for the selected category.",
errDocNotEnabled: "This document category not yet enabled.",
chatBotBenifitsId: "S0035",
betaApp:'GAP',
betaUserRouting: '/app-betauser',
errException: 'Some error occurred. Please try again after some time.',
payslip:'Payslip',
relationID:0,
compensation:'S0028',
RSUApplicable:'S0032',
StockApplicable:'S0034' ,
TotalCompApplicable:'S0060',
TapitApply: 'Apply',
Sparshcontentdetails: 'SparshContentDetailsComponent',
attendanceQuarterly: 'get attendance averagehours quarterly',
attendanceYearly: 'get attendance averagehours yearly',
system: "System",
optionalHoliday: 'optionalHoliday',
pageLoad: 'pageload',
submit:'Submit',
failure:'Failure',
statusUnknown: 'statusUnknown',
singleSwipe:'Singleswipe',
swipeRecords:'Swiperecords',
averageworkinghours: 'averageworkinghours',
beta:'betacomponent',
banner:'Expand banner',
dataPrivacy:'Data privacy load',
reject: 'reject',
approve:'approve',
delete:'delete',
manageService: 'ManageservicesComponent',
takeabreak: 'TakeabreakComponent',
profilepageview: 'Profilecomponent',
financepageview: 'FinanceprofileComponent',
feedbackpageview: 'Feedbackcomponent',
singleSwipeToDo:'Single Swipe',
statusUnknownTodo:'Status Unknown',
singleSwipeID:'30_T_Regularize Single Swipe',
statusUnknownID:'3192_T_Regularize Status Unknown',
services:'services',
peopledetails:'/app-wall/people',
peopleSearchIndex:'/app-wall/peoples-list',
peopleorgchart: '/app-wall/orgchart',
publishings: '/app-wall/people-publishings',
peopleSearchDetails: 'people-search-details',
similarProfile: "similar profile",
orgChart: "org chart",
publishing: "publishing",
orgChartPageId:"orgChart",
publishingPageId: "publishing",
peopleSearchIndexId: "peopleSearchIndex",
payslipyrDropdown:'payslipyearDrpdownChange',
trsID:'trsLinkClick',
strParentsIds:43,
childId:1,
 benefitDetails:'/app-wall/app-generichome/app-benefit-details',
//benefitDetails:'/app-wall/benefit/benefitdetails',
// benefitDetails:'/app-wall/benefit/app-benefit-details',
benefits:'/app-wall/benefits',
benefitsgenModelPath: 'assets/json/benefitGenModel.json',
enrollmentpath:'/app-wall/app-generichome/app-enrollment',
//enrollmentpath:'/app-wall/benefit/app-enrollment',
profileEditBankDetails:'/app-wall/profile/bank-details',
// enrollmentpath:'/app-wall/benefits/app-enrollment',
takeABreakComponent:'TakeABreakComponent', 
feedbackComponent:'FeedbackComponent', 
shareAnIdeaComponent: 'shareAnIdeaComponent',
singleSwipeComponent: 'SingleSwipeComponent',
regularizeStatusComponent: 'RegularizeStatusComponent',
dataPrivacyComponent:'DataPrivacyComponent',
manageServicesComponent: 'ManageServicesComponent',
accept: "Accept",
continue: "Continue",
coverageForCoupe:"SPOUSE",
coverageForFamily:"FAMILY",
covergaeForSingleParent:"CHILDREN",
singleParent:"SINGLE PARENT",
parents:"PARENTS",
inlaws:"IN-LAWS",
siblings:'SIBLINGS',
coverageEmployee:"SINGLE",
couple:'COUPLE',
son:"SON",
daughter:"DAUGHTER",
wife:"WIFE",
father:"FATHER",
mother:"MOTHER",
husband:"HUSBAND",
brother:"BROTHER",
coverageplan:'COVERAGEPLAN',
sister:"SISTER",
fatherinlaw:'FATHER-IN-LAW',
motehrinlaw:'MOTHER-IN-LAW',
other:'OTHER',
yes:'YES',
no:'NO',
relation:'RELATION',
pan:"pan",
aadhaar:"aadhar",
Aadhaar:"Aadhaar",
nsr:"nsr",
passport:"passport"
,profile:"profile"
,adhar:"Unique Identity Number"
,PAN:"Unique Identity Number"
,Passport:"Passport"
,NSR:"National ID Details"
,editadhar:"Edit AADHAAR"
,editPAN:"Edit PANCard"
,editpassport:"Edit Passport"
,editNSR:"Edit NSR"
,requestTypeGet:"get"
,requestTypePost:"post"
,editLanguage: "Edit Language"
,defaultValue:"Select"
,addLanguageTitle:"Add Language Details"
,editLanguageTitle:"Edit Language Details"
,btnAdd:"ADD"
,btnUpdate:"UPDATE"
,language:"Language"
, nationalSkillsRegistry:"National Skills registry"
,requestTypeFreeze:"freeze"
,LateStay : "Apply LateStay"
,dependentEdit:"Dependent Address"
,uniqueEdit:"Unique Identity Number"
,Address:"Address",
OnsiteAddress:"Assignment Address"
,USOnsiteAddress:"USAssignment Address"
,Present:"Present",
Permanent:"Permanent",
Emergency:"Emergency"
,NotApplicable:"NA",
EnrolmentStatus:"ENROL"
,NID:"National ID Details",
bank:"BankDetails",
receiptStoreNav:'/app-wall/app-receiptstore',
claimsSubmissionRoute:'/app-wall/app-claimsSubmission',
receiptStore: 'receipt store',
claimsSubmission: 'raise new claim',
receiptStoreService: 'receiptstore',
domesticNav:'/app-wall/app-domestic',
domestic:'domestic',
domesticService:'domestic',
accommodationcustomNav:'/app-wall/app-accommodation',
accommodationcustom:'accommodation',
accommodationcustomService:'accommodation',
taxiNav:'/app-wall/app-taxi',
taxi:'taxi',
taxiService:'taxi',
editDisability:"Edit Disability",
rdYes: 'rdYes',
rdNo: 'rdNo',
rdND: 'rdND',
aadhardetails:"Aadhar Details"
,aadhardisc:"Aadhaar card number"
,voucherlocation:'VOUCHERLOCATION'
,labelViewHeading: 'Heading'
,labelViewHeaderActions: 'Links'
,labelViewFields: 'fields',
labelViewhighlightFields: 'highlightFields',
labelViewFieldsActions: 'actions',
suAppcatId:10006,
ssAppcatId:10000,
glomoAppcatId:4103,
glomoORFappCatId:"4103",
successForTTD: "success",
failureForTTD: "failure",
applyweekendworking:'apply weekend working',
applyweekendworkingteam:'apply weekend working team',
applyodteam: 'apply od team',
applywfhteam: 'apply wfh team',
errorForTTD: "error",
glomoLandingConfAppId:"4102",
sendbckReason: "{4}",
appTransactionID: "{5}",
genericTemplate:'/app-wall/app-generichome/generictemplate/{0}',
genericTemplatewithappid:'/app-wall/app-generichome/generictemplate/{0}/{1}',
mealVoucher: 'meal voucher',
unifiedsearch: 'app-wall/app-search',
glomoEndDateModification:"6350",
strTrue:"True",
routeToITmetadata:'app-wall/profile/app-income-tax-home',
routeToITSubmission:'app-wall/profile/app-income-tax-submission',
proofSubmission:'1013',
investmentproposal:'1017',
LIC:'1018',
dashboard:'2046',
failed:"failed",
cancel : 'Cancel',
raiseNewClaim: 'raise new claim',
genericTempGlomo:'/app-wall/genericTempGlomo',
smartCardQueryParam: '?city={0}&reasonForApply={1}',
algQueryParam:'?letterType={0}',
addbank:'/app-wall/profile/bankDetails',
bankDetails:'/app-wall/bankdetails',
bankModule:"Employee Bank Account",
editBank: "edit_bank",
profilepageits:'/app-wall/profile/default/2',
raceAndEthnicity:'/app-wall/app-profile/raceAndEthnicity',
empServicespageview:'EmpServicesComponent',
contactSearchpageview:'ContactSearchComponent',
bannerpageview:'BannerComponent',
generichomepageview:'GenerichomeComponent',
myTeampageview:'MyTeamComponent',
myCirclepageview:'MyCircleComponent',
orgChartpageview:'OrgchartComponent',
addEditBankview:'AddEditbankDetailsComponent',
attendanceProfilepageview:'AttendanceProfileComponent',
addEditbankview:'AddEditbankComponent',
bankdetailspageView:'BankdetailsComponent',
salaryAccountpageview:'SalaryAccountComponent',
bankDetailspageview:'BankDetailsComponent',
benefitDetailpageview:'BenefitsDetailsComponent',
benefitpageview:'BenefitsComponent',
login:'LoginComponent',
gridviewComponent:'GridviewComponent',
labelviewComponent:'LabelviewComponent',
negativehoursComponent:'NegativehoursComponent',
dependentProfileComponent:'DependentProfileComponent',
cardViewType1Component:'CardViewType1Component',
cardViewType2Component:'CardViewType2Component',
cardViewType3Component:'CardViewType3Component',
cardViewType4Component:'CardViewType4Component',
cardViewType5Component:'CardViewType5Component',
cardViewType6Component:'CardViewType6Component',
cardViewType7Component:'CardViewType7Component',
cardViewType8Component:'CardViewType8Component',
cardViewType9Component:'CardViewType9Component',
cardViewType10Component:'CardViewType10Component',
cardViewType11Component:'CardViewType11Component',
cardViewType12Component:'CardViewType12Component',
cardViewType13Component:'CardViewType13Component',
labelviews1Component:'Labelviews1Component',
labelviews2Component:'Labelviews2Component',
labelviews3Component:'Labelviews3Component',
labelviews4Component:'Labelviews4Component',
inboxViewComponent:'InboxViewComponent',
inboxsubviewComponent:'InboxsubviewComponent',
cardViewActionsComponent:'CardViewActionsComponent',
cardViewType16Component:'CardViewType16Component',
genFormActionsComponent:'GenFormActionsComponent',
cardViewInboxViewComponent:'CardViewInboxViewComponent',
inboxViewType1Component:'InboxViewType1Component',
inboxViewType2Component:'InboxViewType2Component',
dashboardContentComponent:'DashboardContentComponent',
multipleViewsComponent:'MultipleViewsComponent',
perkCalculatorComponent:'PerkCalculatorComponent',
tabViewActionsComponent:'TabViewActionsComponent',
thingsToKnowComponent:'ThingsToKnowComponent',
claimsubmissionComponent:'ClaimsubmissionComponent',
insidertradingComponent:'InsidertradingComponent',
receiptstoreComponent:'ReceiptstoreComponent',
disabilitydetailsComponent:'DisabilitydetailsComponent',
disabilityprofileComponent:'DisabilityprofileComponent',
editUniqueIdsComponent:'EditUniqueIdsComponent',
editdependentComponent:'EditdependentComponent',
editlanguageComponent:'EditlanguageComponent',
editnationalidComponent:'EditnationalidComponent',
editprofileComponent:'EditprofileComponent',
edocketComponent:'EdocketComponent',
educationProfilePageId:"EducationProfileComponent",
enrollmentFormComponent:'EnrollmentFormComponent',
experienceProfilePageId:"experienceProfileComponent",
financeChartsPageId:"financeChartsComponent",
languageProfilePageId:"languageProfileComponent",
nationalIdProfileComponent:'NationalIdProfileComponent',
passportProfilePageId:"passportProfileComponent",
raceComponent:'RaceComponent',
raceAndEthnicityComponent:'RaceAndEthnicityComponent',
rsuProfilePageId:"rsuProfileComponent",
stocksprofileComponent:'StocksprofileComponent',
uniqueIDPageId:"uniqueIDComponent",
publishingsComponent:'PublishingsComponent',
allEmpServicesPageId:"allEmpServicesComponent",
holidayCalenderComponent:'HolidayCalenderComponent',
recommendedArticlesPageId:"recommendedArticlesComponent",
horizontalTileComponent:'HorizontalTileComponent',
largeTileComponent:'LargeTileComponent',
verticalTileComponent:'VerticalTileComponent',
sparshPageId:"sparshComponent",
mySubCircleComponent:'MySubCircleComponent',
userDetailsPageid:"userDetailsComponent",
userdetailslistComponent:'UserdetailslistComponent',
customtraveldetailsComponent:'CustomtraveldetailsComponent',
bankdetailsProfileComponent:'BankdetailsProfileComponent',
surveyComp: 'surveyComponent',
onsiteUSAddres:'TypeOfAddress', 
usEmergencyContact:'Emergency Contact',
usCountry:'US',
glomoAssignmentID:'7390_T_Glomo Assignment',
countryCode:"{0}",
claimsSubmissionMyatctivity:'/app-wall/app-claimsSubmission',
benefit:'/app-wall/benefit/Benefits',
thingsToDo:'thingstodoPage',
thingstodoSimpleSingleCard:'/app-wall/thingstodo/app-single-view/{id}',
thingstodoSimpleBulkCard:'/app-wall/thingstodo/app-bulk-view/{id}',
thingstodoCommonCard:'/app-wall/thingstodo/app-bulk-view/{id1}/app-common-view/{id2}',
testMicroApp:'/app-wall/microapp/test-micro-app',
raiseDomestic: 'domestic request',
raiseInternational :'international request',
internationalNav:'/app-wall/app-international',
international:'international',
internationalService:'international',
thingstodoCommonCardwithoutchild:'/app-wall/thingstodo/app-common-view/{id}',
thingstodoToMicroApp:'/app-wall/app-emptyComponent',
routeforitswithid:'/app-wall/app-insidertrading/{0}',
routeunifiedsearchTypeahead: '/unisearch/api/v1/search/typeahead?queryText={0}&source=Web&typeAheadType=services,analytics,people,skill,content,solution,proposals,telemetrydata&benefitIds={1}',
routeunifiedsearchPeople: '/unisearch/api/v1/search/typeahead?queryText={0}&source=Web&typeAheadType=people&benefitIds={1}',
routeforinbayaction: '/pfapis/inbaytransactions/inbayTransactions/ActionItemForWeb',
routeforinbayapproval:'/pfapis/inbaytransactions/inbayTransactions/ApprovalItemForWeb',
routeforprofilepic:'/unigraph/api/v1/users/profilepicture?platform=Web',
routeforservices:'/tapit/api/TapIT/GetAllViewServices',
routeforleftmenu:'/tapit/api/TapIT/GetMenuDetails',
routeforgenerictemplates:'/tapit/api/TapIT/GetSectionJSON?intModuleId={0}&strQueryValue={1}',
routeforbenefits:'/benefits/api/Benefits/GetBenefitDetails',
routeforinbayapprovalInternet:'/inbaytransactions/inbaytransactions/ApprovalItemForWeb',
routeforinbayactionInternet:'/inbaytransactions/inbaytransactions/ActionItemForWeb',
// urlForProfilePic:'http://10.177.41.160/api/v1/users/profilepicture?platform=Web',
isMobileflag: "{5}",
fileType1 : 'jpeg',
fileType2 : 'tiff',
fileType3 : 'jpg',
fileType4 : 'png',
fileType5 : 'tif',
filetypes : ['jpeg','tiff','jpg','png','tif'],
fileMaxSize : 67108864,
apifailedmsg : 'Some error occured. Please try again after sometime',
serviceerrormsg : 'We couldn’t retrieve required content. Please try after sometime. In case issue persists, please write to alumni@infosys.com',
takeactionconfirmationmsg : 'We want to take you to the Infosys Careers website for your application details. Please sign in with the login credentials sent to your registered email address by TalentAcquisition@infosys.com.',
dpomsgforjobrecommendation : 'I confirm that the personal information about the referred candidate has been shared with candidate’s permission.',
helpdeskMaintenanceMsg : 'Help Desk is currently offline for scheduled maintenance. We expect to be back in a couple of hours. Thanks for your patience.',
pfdescription: 'PFdescription',
retiralsPf: 'RetiralsPF',
retiralsSA: 'RetiralsSA', 
ahdcategory1: 'Paycheck– W2 – US (Infosys Limited)',
ahdcategory2: 'Payroll - MyADP - US (Infosys Limited)',
ahdcategory3: 'Payroll - Other queries - US (Infosys Limited)'

}
