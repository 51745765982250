export enum EVENTTYPES {
    START = "START",
    IMPRESSION = "IMPRESSION",
    INTERACT = "RESIZE",
    ASSESS = "ASSESS",
    RESPONSE = "RESPONSE",
    INTERRUPT = "INTERRUPT",
    FEEDBACK = "FEEDBACK",
    SHARE = "SHARE",
    AUDIT = "AUDIT",
    ERROR = "ERROR",
    HEARTBEAT = "HEARTBEAT",
    LOG = "LOG",
    SEARCH = "SEARCH",
    METRICS = "METRICS",
    SUMMARY = "SUMMARY",
    EXDATA = "EXDATA",
    END = "END",
    APP="App"
}
