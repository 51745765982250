import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { HttpClient } from '@angular/common/http';
import { JavaServiceBase } from 'src/app/shared/base/javaservice-base';
import { catchError, map } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class HeaderService extends JavaServiceBase {

  baseUrlStatic : string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingName;
  baseUrlDynamic : string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingUrl;
  model : string;
  data;
  observable;
  private subject = new Subject<any>();
  constructor(private httpClient: HttpClient) {
    super();
  }

  getValueForTypeAhead() {
    this.model = this.setModelForJavaService(Appconstants.valuefortypeahead, Appconstants.empty, Appconstants.empty, Appconstants.empty, Appconstants.get);
    console.log("getValueForTypeAhead");
    console.log(this.model,this.baseUrlStatic);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getContentForTypeAheadLaunchers() {
    this.model = this.setModelForJavaService(Appconstants.tapitswaggerlauncher, Appconstants.empty, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getValueForPeopleSearch(txtSearch: string) {
    this.model = this.setModelForJavaService(Appconstants.peoplesearch, txtSearch, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getValueForProjectSearch(txtProjectSearch: string) {
    this.model = this.setModelForJavaService(Appconstants.projectsearch, txtProjectSearch, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getValuesForLocationSearch(txtlocSearch: string) {
    this.model = this.setModelForJavaService(Appconstants.locationsearch, txtlocSearch, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }


  getDetails(url: string, type: string) {
    this.model =this.setModelForJavaService(Appconstants.empty,Appconstants.empty,url,Appconstants.empty,type);
    console.log("getdetails");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic,this.model)
    .pipe(catchError(() => throwError(new Error('error'))));
    
    
  } 

  getLeaveDetails() {
    const envUrl: string = './assets/json/leave.json';
    return this.httpClient.get(envUrl);
  }

  getCompoffDetails() {
    const envUrl: string = './assets/json/compoff.json';
    return this.httpClient.get(envUrl);
  }

  getFormDetails(id: string) {
    this.model = this.setModelForJavaService(Appconstants.formdetails, id, Appconstants.empty, Appconstants.empty, Appconstants.get);
    console.log("getFormDetails");
    console.log(this.baseUrlStatic, this.model);
    return this.httpClient.post(this.baseUrlStatic, this.model).pipe(catchError(() => throwError(new Error('error'))));
    // const baseUrl = 'http://10.82.5.132:8753/api/TapIT/GetSearchActivityUrl?ID=' + id;
    // return this.httpClient.get(baseUrl);
  }

  getDetailsForWFHQueries(url: string) {
    // const baseUrl = Appconstants.wfhPath;
    // return this.httpClient.get(baseUrl);
    this.model =this.setModelForJavaService(Appconstants.empty,Appconstants.empty,url,Appconstants.empty,Appconstants.get);
    console.log("getDetailsForWFHQueries");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic,this.model);
  }

  getDetailsForTableViews(url: string) {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url , Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getDetailsForGenModal() {
    const baseUrl = Appconstants.genModelPath;
    return this.httpClient.get(baseUrl);
  }

  getProjectDetails(txtProjCode: string) { 
    this.model = this.setModelForJavaService(Appconstants.projectcodedetails, txtProjCode, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getDDdetails(url: string, queryString: string){
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url+ "?strLocation=" +queryString, Appconstants.empty, Appconstants.post);
    console.log("getDDdetails");
    console.log(this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getBoadringPoint(url: string, strLocation: string, strArea: string){
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url+ "?strLocation=" +strLocation +"&strArea="+strArea, Appconstants.empty, Appconstants.post);
    console.log("getBoadringPoint");
    console.log(this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  

  postApplyOH(url: string, date: string){
    let requestBody:{applicantMailId: string, ohDate: string};
    let strBody: string;
    requestBody = {applicantMailId: "", ohDate: ""};
    requestBody.ohDate = date;
    strBody = JSON.stringify(requestBody);
    console.log("postApplyOH");
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url , strBody, Appconstants.post);
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  postCancelOH(url: string, LeaveFormId: string){
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url + '?intLeaveFormId='+ LeaveFormId , Appconstants.empty, Appconstants.post);
    console.log("postCancelOH");
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  generateRecord(url: string, type: string){
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url, Appconstants.empty, type);
    console.log("generateRecord");
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getPaidCompOffLocation(date : Date) {
    console.log("getPaidCompOffLocation");
    console.log(date);
    var txtDate = this.convertToDateString(date);
    console.log(txtDate);
    const baseUrl = '';
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,baseUrl , Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  submitSUForm(url: string, suBody: string){

    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, suBody, Appconstants.post);
    console.log("submitSS");
    console.log(this.baseUrlDynamic,this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  fetchAdvLeaveElg()  {
    const baseUrl = '';
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,baseUrl , Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  getBetaUsersData(){    
    let url: string = "";
    if (this.data) {
      return Observable.of(this.data);
    } else if (this.observable) {
      return this.observable;
    }
    else{
      if(environment.production == true){
        if(environment.isInternet.toLowerCase() == "intranet"){
          url = environment.gatewayBaseUrlIntranet + Appconstants.routeforbenefits;
        }
        else{
          url = environment.gatewayBaseUrlInternet + Appconstants.routeforbenefits;
        }
        this.observable = this.httpClient.get(url, {
          observe: 'response'
        }) .map(response =>  {
          this.data = response.body;
          return this.data;
        })  .share();
        return this.observable;
      
      }    
      else{
         url= environment.benfitsUrl;
        this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url, Appconstants.empty, Appconstants.get);
        this.observable = this.httpClient.post(this.baseUrlDynamic, this.model, {
          observe: 'response'
        }).map(response =>  {
          console.log("response for obs", response);
          this.data = response.body;
          return this.data;
        }).share();
        console.log("response for obs1",  this.observable);
        return this.observable;
      } 
    } 
    
  }

  

  //People Search
  getTypeAheadUsersFromService(searchText:string)
  {
    const baseUrl  = environment.searchBaseUrl+Appconstants.typeAheadUrl+searchText+"&source=Mobile&typeAheadType=people";
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, baseUrl, Appconstants.empty, Appconstants.get);
    console.log("prople service");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model).pipe(map(response => response));
    
  }
  //end ppl search


  getDDDetailsNegativeHours(url: string) {
    console.log(url);
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log("getFormDetailsNegativeHours");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model).pipe(catchError(() => throwError(new Error('error'))));
   

  }


  getFormDetailsNegativeHours(url: string, data: string) {
    console.log("getFormDetailsNegativeHours");
    console.log(url);
    url = url.replace('{0}',data)
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
    console.log("getFormDetailsNegativeHours");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model).pipe(catchError(() => throwError(new Error('error'))));
    

  }

  submitNegativeHours(model:object, url:string){
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(model), Appconstants.post);
    console.log("submitNegativeHours");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  getUnifiedSearchResult(strSearchValue: string){
    const url: string = environment.unifiedSearch.replace("{0}",strSearchValue);
    
    return this.httpClient.get(url);
  }

  getUnifiedSearchResultForTypeahead(strSearchValue: string, benefitsId: string, blnPeopleSearh: boolean){
    let url: string;
    if(environment.production == true){
      if(environment.isInternet.toLowerCase() == "intranet"){
        url = (blnPeopleSearh === true) ?
         environment.gatewayBaseUrlIntranet + Appconstants.routeunifiedsearchPeople :
         environment.gatewayBaseUrlIntranet + Appconstants.routeunifiedsearchTypeahead;
      }
      else{
        url = (blnPeopleSearh === true) ?
        environment.gatewayBaseUrlInternet + Appconstants.routeunifiedsearchPeople :
        environment.gatewayBaseUrlInternet + Appconstants.routeunifiedsearchTypeahead;
      }
    }
    else{
      if(blnPeopleSearh === true){
        url = environment.unifiedSearchForTypeaheadpeople;
      }
      else{
        url = environment.unifiedSearchForTypeahead;
      }
    }
    url = url.replace("{0}",strSearchValue).replace("{1}",benefitsId);
 
    
    return this.httpClient.get(url);
  }
  getFeedbackForSearch(feedbackUrl:string){
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,feedbackUrl, Appconstants.empty, Appconstants.get);
    console.log("Feedback send");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }

  
  getProfileImage(){
    let url: string = "";
      url= environment.alumniProfile;     
     return this.httpClient.get(url).pipe(catchError(() => throwError(new Error('error'))));
     
  }

  getProfilePic(): Observable<any> {
    return this.subject.asObservable();
}

sendByteArray(message: string) {
  this.subject.next({ text: message });
}

  getUserType(): Observable<any> {
    return this.httpClient.post<any>(environment.isOldPortalUserLink,{});
  }

}
