import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { Appconstants } from './shared/constants/app-constants';
import { EnvLoaderService } from 'src/environments/environmentLoader.service';


@Injectable()
export class AuthGuardApp implements CanActivate {
  constructor(private envLoaderSerive: EnvLoaderService){
    
  }

  async canActivate() {
    console.log("canActivate new11");
    let envjson = await this.envLoaderSerive.getEnvDataNew();
    console.log("canActivate new");
    console.log(envjson);
      if(environment.production){
        environment.javaServiceBaseUrl = envjson['JavaServiceBaseUrl'];
        environment.telemetryBaseUrl = envjson['TelemetryBaseUrl'];
        environment.telemetryEndPoint = envjson['TelemetryEndPoint'];
        environment.searchBaseUrl = envjson['peopleSearchUrl'];
        environment.benfitsUrl = envjson['benfitsUrl'];
        environment.type = envjson['Type'];
        environment.isInternet = envjson['IsInternet'];
        environment.applyOD = envjson['applyOD'];
        environment.applyLeave = envjson['applyLeave'];
        environment.applyWFH = envjson['applyWFH'];
        environment.unifiedSearch = envjson['unifiedSearch'];
        environment.unifiedSearchForTypeahead = envjson['unifiedSearchForTypeahead'];
        environment.idlesessiontime = envjson['idlesessiontime'];
        environment.gatewayBaseUrlIntranet= envjson['gatewayBaseUrlIntranet'];
        environment.gatewayBaseUrlInternet= envjson['gatewayBaseUrlInternet'];
        environment.previousyearChildId = envjson['previousyearChildId'];
        environment.claimsSubmissionID= envjson['claimsSubmissionID'];
        environment.sectionJSON=envjson['sectionJSON'];
        environment.ahdcategoryfaq=envjson['ahdcategoryfaq'];
        environment.menu=envjson['menu'];
        environment.modules=envjson['modules'];
        environment.alumniProfile=envjson['alumniProfile'];
        environment.oldalumnilink=envjson['oldalumnilink'];
        environment.emailUrl=envjson['emailUrl'];
        environment.dpConsentModuleId = envjson['dpconsentmoduleid'];
        environment.jobsTrackingModuleId = envjson['jobsTrackingModuleId'];
        environment.profileModuleId = envjson['profilemoduleid'];
        environment.onboardModuleid = envjson['onboardModuleid'];
        environment.resetpassword=envjson['resetpassword'];
        environment.changeofmailidservice=envjson['changeofmailidservice'];
        environment.empnoservice=envjson['empnoservice'];
        environment.jobApplyLink=envjson['jobApplyLink'];
        environment.captchaurl=envjson['captchaurl'];
        environment.registrationSave=envjson['registrationSave'];
        environment.dpoRegistrationService=envjson['dpoRegistrationService'];
        environment.retiralsTemplateModuleId = envjson['retiralsTemplateModuleId'];
        environment.edocketTemplateModuleId=envjson['edocketTemplateModuleId'];
        environment.ahdTemplateModuleId=envjson['ahdTemplateModuleId'];
        environment.raiserequestModuleId=envjson['raiserequestModuleId'];
        environment.manageRequestmoduleId=envjson['manageRequestmoduleId'];
        environment.createrequestTemplateModuleId=envjson['createrequestTemplateModuleId'];
        environment.isOldPortalUserLink = envjson['isOldPortalUserLink'];
        environment.otherRegionJobTrackerLink = envjson['otherRegionJobTrackerLink'];
        environment.comingSoonModuleId = envjson['comingSoonModuleId'];
        environment.appDownJsonLink = envjson['appDownJsonLink']; 
        environment.faqsModuleId = envjson['faqsModuleId'];
        environment.feedbackGet=envjson['feedbackGet'];
        environment.getBenefits=envjson['getBenefits'];
        environment.dpoValidationPQ=envjson['dpoValidationPQ'];
        environment.sourceIdILIndia=envjson['sourceIdILIndia'];
        environment.sourceIdIBPMIndia=envjson['sourceIdIBPMIndia'];
        environment.sourceIdILChina=envjson['sourceIdILChina'];
        environment.sourceIdIBPMChina=envjson['sourceIdIBPMChina'];
        environment.locationurl=envjson['locationurl'];
        environment.separationStatusUrl=envjson['separationStatusUrl'];
        environment.ahdfaqModuleId=envjson['ahdfaqModuleId'];
        environment.fileValidator=envjson['fileValidator'];
        environment.blBotFlag=envjson['blBotFlag'];
        environment.botName=envjson['botName'];
        environment.baseURL=envjson['baseURL'];
        environment.autosuggestion=envjson['autosuggestion'];
        environment.Redirect_Uri=envjson['RedirectURI'];
      }
    return true;
  }
}
