import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
@Injectable({
    providedIn: 'root'
  })
  export class EnvLoaderService {
    constructor(private http: HttpClient) { }
  
    getEnvData() {
      const envUrl: string = './assets/environments/environment.json';
      return this.http.get(envUrl);
    }


    async getEnvDataNew() {
      const envUrl: string = './assets/environments/environment.json';
      return await this.http.get(envUrl).toPromise();
    }
}
