import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { WallComponent } from "./core/wall/wall.component";
import { AuthGuardApp } from "./auth.guard";
import { HomeComponent } from "./core/home/home.component";
import { from } from "rxjs";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./core/login/login.component";
import { AuthGuardKeycloak } from './auth.keycloakguard';
import { WelcomeComponent } from "./welcome/welcome.component";
import { RegistrationformComponent } from './registrationform/registrationform.component';
import { PasswordComponent } from './password/password.component';
import { EmailidchangeComponent } from "./emailidchange/emailidchange.component";
const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    pathMatch: "full"
    , canActivate: [AuthGuardApp],
  },

  {
    path: "app-welcome",
    component: WelcomeComponent,
    pathMatch: "full"
    , canActivate: [AuthGuardApp],
  },
  {
    path: "app-registration",
    component: RegistrationformComponent,
    pathMatch: "full"
    , canActivate: [AuthGuardApp],
  },
  {
    path :"app-password",
    component: PasswordComponent,
    pathMatch: "full"
    , canActivate: [AuthGuardApp],
  },

 
  {
    path :"app-emailidchange",
    component: EmailidchangeComponent,
    pathMatch: "full"
    , canActivate: [AuthGuardApp],
  },
  {
    path: "app-wall",
    component: WallComponent,
    canActivate: [AuthGuardApp,AuthGuardKeycloak],
    data: {
      breadcrumb: "Home",
    },
    
    children: [
      {
        path: "app-generichome",
        loadChildren:
          "./form/generictemplate/generictemplate.module#GenericTemplateModule", canActivate: [AuthGuardApp, AuthGuardKeycloak]
      },
      {
        path: "app-registrationform",
        component: RegistrationformComponent,
        pathMatch: "full"
       
      },
      { path: '**', redirectTo: 'app-wall', canActivate: [AuthGuardApp,AuthGuardKeycloak] },
  
    ],
  },
  { path: '**', redirectTo: 'app-wall', canActivate: [AuthGuardApp,AuthGuardKeycloak] },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
