// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  type: 'dev',
  production: true,
  telemetryBaseUrl: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/telemetry/',
  telemetryEndPoint: 'v1/telemetry',
  isInternet: "dev",
  idlesessiontime: "900",
  javaServiceBaseUrl: 'https://isgatewaytst.ad.infosys.com/ehub/',
  sectionJSON: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/ui/api/generictemplate/GetSection?intModuleId={0}&strQueryValue={1}',
  ahdcategoryfaq: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/ui/api/generictemplate/getCategoryFAQ?category={0}',
  gatewayBaseUrlIntranet: 'https://isgatewaytst.ad.infosys.com',
  gatewayBaseUrlInternet: 'https://isgatewaytst.ad.infosys.com',
  gatewayBaseUrl: 'https://isgatewaytst',
  ehipPlaDetailsUrl: '',
  menu: 'https://itgatewaytst.infosys.com/alumniui/api/generictemplate/GetMenu',
  modules: 'https://itgatewaytst.infosys.com/alumniui/api/generictemplate/GetMenu',
  alumniProfile: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/int/springboot/alumni/downloadImage',
  captchaurl: "",
  oldalumnilink: 'http://isvrectwt01/InfosysGlobalAlumni/Aspx/IGAPLanding.aspx',
  emailUrl: "https://itgatewaytst.infosys.com/gatewayalumni/alumni/iap2kanexainteg/springboot/alumni/kanexa/sendMailer",
  registrationSave: "",
  searchBaseUrl: "",
  benfitsUrl: "",
  unifiedSearch: "",
  unifiedSearchForTypeaheadpeople: "",
  unifiedSearchForTypeahead: "",
  menuedetails: "",
  HIPModuleId: "",
  HIPChoosePlanChildId: "",
  EFHIPSummaryId: "",
  mvValidationId: "",
  addPriorStaySelfChildId: "",
  previousyearChildId: "",
  previousyearChildIdEFHIP: "",
  fileuploadReg: "",
  fileuploadDereg: "",
  childIdChoosePlanHIP: "",
  paymentUrlForEFHIP: "",
  claimsSubmissionID: "",
  applyOD: "",
  applyWFH: "",
  applyLeave: "",
  // javaServiceBaseUrl: 'http://10.68.191.109/',
  dpConsentModuleId: 6,
  profileModuleId: 2,
  faqsModuleId: 17,
  retiralsTemplateModuleId: 12,
  edocketTemplateModuleId: 30,
  ahdTemplateModuleId: 36,
  raiserequestModuleId: 37,
  manageRequestmoduleId: 35,
  createrequestTemplateModuleId: 39,
  comingSoonModuleId: 15,
  jobsModuleId: 9,
  jobsTrackingModuleId: 11,
  onboardModuleid: 44,
  getBenefits: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/benefitconf/springboot/alumni/getBenefits',
  dpoValidationPQ: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/iapmeetalumni/springboot/alumni/popupValidation',
  // registrationSavedev:'http://10.82.102.31:9082/alumni/signin/validationOfFormDataAndSave'
  resetpassword: 'https://itgatewaytst.infosys.com/platform/alumni/iap/IAP2SignInInternetsrv/alumni/signin/resetPassword',
  dpoRegistrationService: "https://itgatewaytst.infosys.com/platform/alumni/iap/IAP2SignInInternetsrv/alumni/signin/getDPOClause",
  isOldPortalUserLink: "https://itgatewaytst.infosys.com/gateway/lplexapi/api/IGAP/IsAlumniRegisteredUser",
  // intapPrivacyUrl:'https://intaptest.infosysapps.com/alumni/intapcareers/tnc/getTermsNConditions?compHiringTypeId=1&docName=privacyconsent',
  jobApplyLink: "https://alumnitest.infosysapps.com/app-wall/app-generichome/generictemplate/",
  otherRegionJobTrackerLink: "https://smartdreamers.com/infosysstage/infosys-1",
  // otherRegionProfileUpdateLink:'https://smartdreamers.com/infosysstage/infosys-1',
  appDownJsonLink: "",
  empnoservice: 'https://itgatewaytst.infosys.com/platform/iap/IAP2SignInInternetsrv/alumni/signin/getEmpNoFromMembers',
  feedbackGet: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/iap2content/springboot/alumni/GenericGetJson?type=FeedbackGet',
  sourceIdILIndia: 1,
  sourceIdIBPMIndia: 41,
  sourceIdILChina: 61,
  sourceIdIBPMChina: 91,
  locationurl: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/int/springboot/alumni/getLocation',
  separationStatusUrl: 'https://itgatewaytst.infosys.com/gatewayalumni/alumni/benefitconf/springboot/alumni/getSeparationStatus',
  ahdfaqModuleId: 45,
  changeofmailidservice: 'https://itgatewaytst.infosys.com/platform/alumni/iap/IAP2SignInInternetsrv/alumni/signin/emailChange',
  fileValidator: 'https://itgatewaytst.infosys.com/alumni/filevalidation/filevalidator/validatefile',
  blBotFlag: 'enabled',
  botName: 'alumni',
  baseURL: 'https://itgatewaytst.infopsys.com/gatewayalumni/alumni/akscht/api/request',
  autosuggestion: 'https://infymebotft.ad.infosys.com/api/auto-suggestion/',
  Redirect_Uri: 'https://identitypoc.infosysapps.com/auth/realms/alumniportal/protocol/openid-connect/logout?post_logout_redirect_uri='
}
