import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { GenerictemplateService } from '../form/generictemplate/generictemplate.service';
import {MessageSnackBarService} from '../shared/component/message-snackbar/message-snackbar.service';
import { Appconstants } from '../shared/constants/app-constants';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  emailinput:FormControl;
  strMessage: string;
  blnError: boolean;
  blnSuccess: boolean;
  constructor(private genericservice : GenerictemplateService, private router:Router, private messageservice: MessageSnackBarService) { }

  ngOnInit() {
    this.emailinput=new FormControl('',[
      Validators.required,
      Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ])

  }
  reset(){
    if(this.emailinput.invalid) {
      this.emailinput.markAllAsTouched()
      return;
    }
    this.blnError=false;
    this.blnSuccess=false;
       this.genericservice.reset(this.emailinput.value)
       .subscribe((data:String)=>{
        console.log(data);
        if(data==="tesertensliam"){
        this.messageservice.openMessageBar({type:'success',message:'New password has been sent to your email address'});
         this.emailinput.reset();
        }
        if(data==="tcerroclniiame"){
        
         this.messageservice.openMessageBar({type:'error',message:"User doesn't exist"})
        
        }
        if(data==="duruococoroerr"){
         this.messageservice.openMessageBar({type:'error',message:Appconstants.apifailedmsg});
        }
      },
      err => {
        console.log(err);
        this.messageservice.openMessageBar({type:'error',message:Appconstants.apifailedmsg});
      }
  ) 
     
     }
     navigateback(){
       this.router.navigate(['app-wall/app-generichome/generictemplate/'+environment.dpConsentModuleId]);
     }
     }
   

