import { Injectable } from '@angular/core';
import { JavaServiceBase } from 'src/app/shared/base/javaservice-base';
import { environment } from 'src/environments/environment.prod';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})

export class NavBarService extends JavaServiceBase {
  baseUrlStatic: string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingName;
  baseUrlDynamic: string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingUrl;
  model: string;
  data;
  observable;
  constructor(private httpClient: HttpClient) {
    super();
  }

  getNavBarDetails() {   
    let url: string = "";
    if (this.data) {
      return Observable.of(this.data);
    } else if (this.observable) {
      return this.observable;
    }
    else{
      if (environment.production == true) {
        if (environment.isInternet.toLowerCase() == "intranet") {
          url = environment.gatewayBaseUrlIntranet + Appconstants.routeforleftmenu;
        }
        else {
          url = environment.gatewayBaseUrlInternet + Appconstants.routeforleftmenu;
        }

         this.observable = this.httpClient.get(url,{
          observe: 'response'
        })
        .map(response =>  {
          this.observable = null;
          this.data = response.body;
          return this.data;   
        })
        .share();
        return this.observable;
      }
      else {
        url = environment.menuedetails;
        this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, Appconstants.empty, Appconstants.get);
        this.observable = this.httpClient.post(this.baseUrlDynamic, this.model,{
          observe: 'response'
        })
        .map(response =>  {
          this.observable = null;
          this.data = response.body;
          return this.data;   
        })
        .share();
        return this.observable;
      }
    }
   

    // let url='';
    // if (environment.production) {
    //   url = 'https://employeehubtst.infosysapps.com/ift/assets/json/nav_bar.json';
    // }
    // else {
    //   url = '/assets/json/nav_bar.json';
    // }
    // return this.httpClient.get(url);
  }
}