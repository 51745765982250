import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { JavaServiceBase } from '../shared/base/javaservice-base';
import { Appconstants } from '../shared/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class RegistrationformService extends JavaServiceBase{
  baseUrlStatic: string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingName;
  baseUrlDynamic: string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingUrl;
  model: string;

  constructor(private httpClient:HttpClient) { 
    super()
  }
  getService(url,inputModel,type){
    let result:any;
    url = environment.registrationSave;
    if(type.toLowerCase() == 'get'){
   
    this.model =this.setModelForJavaService(Appconstants.empty,Appconstants.empty,url,Appconstants.empty,type);
    return this.httpClient.post(this.baseUrlDynamic,this.model);
  }
  else{
      console.log(JSON.stringify(inputModel));
      return this.httpClient.post(url, JSON.stringify(inputModel)).pipe(catchError(this.handleError));
    }
    // this.model =this.setModelForJavaService(Appconstants.empty,Appconstants.empty,url,JSON.stringify(inputModel),type);
    // console.log("post Service call"+this.model);
    // result= this.httpClient.post(this.baseUrlDynamic,this.model);
    // console.log(result);
    // return result;
  }
  
 getCaptchaService(){
  let url = environment.captchaurl;
  const requestoptions :object={
    withCredentials:false,
    observe:'response',
    responseType: 'blob'
    }
   
  let result:any;
  result= this.httpClient.get(url,requestoptions).pipe(catchError(this.handleError));
  return result;

}

  private handleError(err: HttpErrorResponse) {
    let errMsg = '';
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred: ', err.message);
      errMsg = err.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errMsg = 'Backend returned code: ' + err.status;
    }
    // console.log(errMsg);
    return throwError(errMsg);
  }

  getDpoService(){
    let url = environment.dpoRegistrationService;
    let result:any;
    result= this.httpClient.get(url).pipe(catchError(this.handleError));
    console.log("afterdpo call " + result)
    return result;
  }
}
