import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef, DoBootstrap } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpServiceInterceptor } from './httpserviceinterceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WallComponent } from './core/wall/wall.component';
import { AuthGuardApp } from './auth.guard';
import { AppConfigService } from './shared/helpers/app-config.service';
import { TextareaComponent } from './shared/atoms/textarea/textarea.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './core/home/home.component';
import { HeaderComponent } from './core/header/header.component';
import { NavBarComponent } from './core/nav-bar/nav-bar.component';
import { FooterComponent } from './core/footer/footer.component';

import { MatChipsModule } from '@angular/material/chips';
import { NgxMultiLineEllipsisModule } from "ngx-multi-line-ellipsis";

import { LoginComponent } from './core/login/login.component';
import { KeycloakAngularModule, KeycloakConfig, KeycloakService } from 'keycloak-angular';
import { loggedInUserProfile } from 'src/app/shared/base/loggedinuser-base'
import { KeycloakConfigService } from './shared/services/key-cloak.service';
import { AuthGuardKeycloak } from './auth.keycloakguard';
import { NavMenuComponent } from './core/nav-menu/nav-menu.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { RegistrationformComponent } from './registrationform/registrationform.component';
import { DynamicScriptLoaderService } from './shared/helpers/dynamic-script-loader';
import * as jwt_decode from 'jwt-decode';
import { PasswordComponent } from './password/password.component';
import { MessageSnackbarComponent } from './shared/component/message-snackbar/message-snackbar.component';
import { DownTimeMsgComponent } from './core/down-time-msg/down-time-msg.component';
import { AppDownTimeService } from './shared/services/app-down-time.service';
import { FeedbackComponent } from './feedback/feedback.component';
import { EmailidchangeComponent } from './emailidchange/emailidchange.component';

const keycloakService = new KeycloakService();

let authConfig: any;
export function authConfigFactory() {
  console.log("inside authConfigFactory");
  console.log(authConfig);
  return authConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    WallComponent,
    TextareaComponent,
    HomeComponent,
    HeaderComponent,
    NavBarComponent,
    FooterComponent,
    LoginComponent,
    NavMenuComponent,
    WelcomeComponent,
    RegistrationformComponent,
    PasswordComponent,
    MessageSnackbarComponent,
    DownTimeMsgComponent,
    FeedbackComponent,
    EmailidchangeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    MatChipsModule,
    NgxMultiLineEllipsisModule,
    KeycloakAngularModule
  ],
  exports: [],
  entryComponents: [AppComponent, MessageSnackbarComponent],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true,
    },
    AuthGuardApp, AuthGuardKeycloak, AppConfigService, DynamicScriptLoaderService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule implements DoBootstrap {
  URL: string;
  REALM: string;
  CLIENTID: string;
  keycloakConfig: KeycloakConfig;
  loggedInUser: loggedInUserProfile;
  constructor(
    private appConfigService: AppConfigService, private appDownTimeService: AppDownTimeService) {
    this.getKeyConfig();
  }

  getKeyConfig() {

  }

  ngDoBootstrap(appRef: ApplicationRef) {
    this.appConfigService.loadAppConfig()
      .then(() => {
        return this.appDownTimeService.getAppDownObj();
      })
      .then(() => {
        this.keycloakConfig = {
          clientId: this.appConfigService.getConfig().ClientID,
          realm: this.appConfigService.getConfig().realm,
          url: this.appConfigService.getConfig().url
        }
        console.log("this.keycloakConfig value ",this.keycloakConfig);

        keycloakService
          .init({
            config: this.keycloakConfig,
            loadUserProfileAtStartUp: false,
            initOptions: {
              onLoad: 'check-sso',
              checkLoginIframe: false
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: [],
            bearerPrefix: 'Bearer'
          })
          .then(() => {
            appRef.bootstrap(AppComponent);
            keycloakService.getToken(false).then((token) => {
              var jwt = jwt_decode(token);
              localStorage.setItem("access-token", token);
              localStorage.setItem("authenticated", JSON.stringify(true));
              this.loggedInUser = {
                username: jwt.email,
                email: jwt.email,
                firstName: jwt.given_name,
                lastName: jwt.family_name,
                middleName: jwt.middle_name,
                emailVerified: jwt.email_verified,
                attributes: {}
              }
              localStorage.setItem("loggedInUser", JSON.stringify(this.loggedInUser));

            })
          })
          .catch(error => console.error('[ngDoBootstrap] init Keycloak failed', error));
      })

  }
} 
