export enum INTERACTEVENTS {
    CLICK = "CLICK",
    SCROLL = "SCROLL",
    RESIZE = "RESIZE",
    ZOOM = "ZOOM",
    NAVIGATE = "NAVIGATE",
    KEYPRESS ="KEYPRESS",
    LOGOUT="LOGOUT",
    ENTER="ENTER"
}

export enum IMPRESSIONEVENTS {
    VIEW = "VIEW",
    DETAIL = "DETAIL",
    LIST = "LIST",
    SEARCH = "SEARCH",
    EDIT = "EDIT",
    FORM = "FORM",
    TAB = "TAB",
    FILEUPLOAD = "FILEUPLOAD"
}

export enum LOGEVENTS {
    SYSTEM = "SYSTEM",
    PROCESS = "PROCESS",
    API_ACCESS = "API_ACCESS",
    API_CALL = "API_CALL",
    JOB = "JOB",
    APP_UPDATE = "APP_UPDATE"
}
