import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { AppConfig } from '../models/app-config';



@Injectable()
export class AppConfigService {
  static appConfig : AppConfig;
  static appEnvData: any;
  private dataLoc: string;
  constructor(private handler: HttpBackend) { }

  loadAppConfig() {
    console.log("load config",environment.production);
    if(environment.production){
      this.dataLoc = './assets/environments/environment.json';
    }
    else{
      this.dataLoc = './assets/environments/environment-dev.json';
    }
    return new Promise<void>((resolve, reject) =>  {
      new HttpClient(this.handler).get(this.dataLoc).toPromise().then((response: AppConfig) => {
        // AppConfigService.appConfig = <AppConfig>response; /* Commented for SonarQube */
        console.log("auth guard new");
        AppConfigService.appEnvData = response;
        AppConfigService.appConfig = response; /* SonarQube fix */
        AppConfigService.appConfig.ClientID= response["ClientID"];
        AppConfigService.appConfig.RedirectURI= response["RedirectURI"];
        AppConfigService.appConfig.Tenant= response["Tenant"];
        AppConfigService.appConfig.TokenEndpoint= response["TokenEndpoint"];
        AppConfigService.appConfig.IsInternet= response["IsInternet"];
        AppConfigService.appConfig.realm= response["realm"];
        AppConfigService.appConfig.url= response["url"];
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${this.dataLoc}': ${JSON.stringify(response)}`);
     });
    });
  }
  getConfig() {
    console.log("Get Config");
    console.log(AppConfigService.appConfig);
    return AppConfigService.appConfig;
  }
}

