import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

const msgIconConf = {
  error:{bg:"DSA_wb_alert-danger",icon:"error-big-icon"},
  success:{bg:"DSA_wb_alert-success",icon:"success-big-icon"},
  info:{bg:"DSA_wb_alert-info",icon:"info-big-icon"},
  warning:{bg:"DSA_wb_alert-warning",icon:"alert-icon"},
}

@Component({
  selector: 'app-message-snackbar',
  template: `
  <div id="DSA_msgCtr" [class]="bgClass" *ngIf="isLoaded">
    <i [class]="iconClass"></i>
    <div class="d-inline align-middle">{{data.message}}</div>
    <a href="javascript:void(0);" (click)="dismissSnackBar()" class="icon white x-16 close-icon DSA_vertical-RightCenter" aria-label="close message bar"></a>
  </div>
  `,
  styles: [
    `
    .DSA_wb_alert-success,
    .DSA_wb_alert-info,
    .DSA_wb_alert-warning {
      color: #FFFFFF;
      padding: 16px 50px;
    }
    .success-big-icon,
    .info-big-icon,
    .alert-icon,
    .error-big-icon {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
    .DSA_topbarMessage {
      margin-top: 96px !important;
    }

    @media screen and (max-width:1024px) {
      .DSA_topbarMessage {
        margin-top: 80px !important;
      }
    }

    @media screen and (max-width:895px) and (min-width:769px) {
      .DSA_msgCtr {
        width:715px
      }
    }
    `
  ]
})
export class MessageSnackbarComponent implements OnInit, AfterViewInit {

  isLoaded:boolean = false;
  bgClass:string=null;
  iconClass:string=null;
  
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {type:string,message:string},private matSnackBar:MatSnackBar) { }

  ngOnInit() {
    console.log("data in snack-bar ", this.data);
    let topMarginClass = 'marT16';
    let headerComp = $("#scrollHeader")
    if(typeof (headerComp) !== 'undefined' && headerComp.length && headerComp.length!==0) topMarginClass = "DSA_topbarMessage"
    this.bgClass = `DSA_msgCtr DSA_wb_alert ${msgIconConf[this.data.type].bg} marB16 ${topMarginClass}`;
    this.iconClass = `icon white ${msgIconConf[this.data.type].icon} iconinline marR8`;
    this.isLoaded = true;
  }

  ngAfterViewInit() {
    // $('#DSA_msgCtr').removeClass().addClass(`DSA_msgCtr DSA_wb_alert ${msgIconConf[this.data.type].bg} marB16 DSA_topbarMessage`);
    // $('#DSA_msgCtr i').removeClass().addClass(`icon white ${msgIconConf[this.data.type].icon} iconinline marR8`);
  }

  dismissSnackBar() {
    this.matSnackBar.dismiss();
  }

}
