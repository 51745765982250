import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AppUtilServices } from 'src/app/shared/services/app-util.services';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { TelemetryService } from '@infosys-it/angular-telemetry-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { IMPRESSIONEVENTS, INTERACTEVENTS } from 'src/app/shared/constants';
import { debounceTime } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment.prod';
import { ComponentName } from 'src/app/shared/constants/component-name.constants';
import { GenerictemplateService } from 'src/app/form/generictemplate/generictemplate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedIn: boolean = false;
  loggedInUser: string = "";
  subscription: Subscription;
  helpdeskMaintenanceMsg: string = Appconstants.helpdeskMaintenanceMsg;
  strDPRoute: string = "app-wall/app-generichome/generictemplate/" + environment.dpConsentModuleId;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;
  constructor(private _eref: ElementRef, private codeCp: AppUtilServices,
    private tapItService: HeaderService, private router: Router, private telemetryService: TelemetryService,
    private headerService: HeaderService, private keycloakService: KeycloakService, private activatedRoute: ActivatedRoute,
    private genericTemplateService: GenerictemplateService, private modalService: NgbModal) {

    this.keycloakService.isLoggedIn().then((loggedIn) => {
      console.log("logged in", loggedIn);
      this.loggedIn = loggedIn;
      if (this.loggedIn == true) {
        this.fnLogInteract('Login Successful');
        this.bindProfileImage();
        this.subscribeToHeaderObs();
      }
    });

  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  formControlSearch = new FormControl();
  blnBenefitsLoad: boolean = false;
  unifiedSearchResult: object[] = [];
  unifiedSearchGroup: object[] = [];
  benefitsData: string = "";
  textboxValue: string = "";
  subject = new Subject();
  imgByte1: object[];
  imgByteData: object[];
  imgServiceByte: any;


  imgByte1string: string;
  imgByteDatastring: string;
  errorProfilePic: boolean = true;
  unifiedSearchFilteredResult: { groupName: string, result: object[] }[] = [];
  benefitList: any;
  ngOnInit() {
    this.fnLogImpressionEvents("header component called");
    this.getBenefits().subscribe((benefits: any) => {
      console.log("benefits", benefits)
      this.benefitList = benefits
      console.log("benefitList", this.benefitList)
    },
      err => {
        console.log(err);
        this.fnError(err);
      });
    this.codeCp.middleHeight();
    this.subject.pipe(
      debounceTime(500)
    ).subscribe(event => {
      this.fnBindSearch(event);
    });
  }

  getBenefits() {
    let url = environment.getBenefits;
    return this.genericTemplateService.fetchJson(url);
  }
  search(event: Event) {
    this.subject.next(event);
    // if ((<KeyboardEvent>(event)).key === 'Enter')
    // {
    //   this.fnCreateSearchResult(event);
    // }
  }

  public fnBindSearch(event) {
    console.log(event);

    this.textboxValue = this.formControlSearch.value;
    if (event['key'] !== 'ArrowDown' && event['key'] !== 'ArrowUp') {
      this.unifiedSearchFilteredResult = [];

      if (this.formControlSearch.value.length > Appconstants.two) {
        this.fnCreateSearchResult(event);
      } else {
        this.unifiedSearchFilteredResult = [];
      }
    }
  }

  fnSearchResult(searchResult: object) {
    let searchData: string;
    let mailId: string[];
    let typeAheadMetadata01: string;
    searchData = searchResult['typeAheadResult'];
    console.log('peopleSelected');
    console.log(searchResult);
    let searchCategory: string = searchResult['typeAheadType'];
    let isNumber;
    let telemetryEventData = {
      type: INTERACTEVENTS.CLICK,
      //subtype: this.data.split('_')[2],
      id: searchData + " " + INTERACTEVENTS.CLICK,
      pageid: Appconstants.people
    }
    this.telemetryService.interact(telemetryEventData);
    if (searchCategory.toLowerCase() == 'services' && searchResult['typeAheadMetadata01'] !== undefined) {
      isNumber = this.fnIsnumber(searchResult['typeAheadMetadata01']);

      if (isNumber != null) {
        this.router.navigate([Appconstants.genericTemplate.replace('{0}', searchResult['typeAheadMetadata01'])]);
      }

      else {
        if (searchResult['typeAheadMetadata01'] == 'insidertrading') {
          this.router.navigate([Appconstants.routeforitswithid.replace('{0}', searchResult['typeAheadResult'])]);
        }
        else if (searchResult['typeAheadMetadata01'].toLowerCase() === 'claimsubmission') {
          this.router.navigate([Appconstants.claimsSubmissionRoute]);
        }
      }

    }
    // else if(this.formControlSearch.value)
    else if (searchCategory.toLowerCase() == 'people') {
      typeAheadMetadata01 = searchResult['typeAheadMetadata01'];
      mailId = typeAheadMetadata01.split("@");
      if (this.textboxValue.length > 0 && this.textboxValue.charAt(0) == "@") {
        searchData = mailId[0] + '_' + 'people';
      }
      else {
        searchData = mailId[0] + '_' + 'all';
      }
      this.router.navigate([Appconstants.unifiedsearch, { searchData }]);
    }
    else {
      searchData = searchData + '_' + 'all';
      this.router.navigate([Appconstants.unifiedsearch, { searchData }]);
    }

  }

  fnIsnumber(mystring: string) {

    var matches = mystring.match(/\d+/g);
    return matches;
  }


  async fnCreateSearchResult(event: Event) {
    let searchData: string = "";
    searchData = this.formControlSearch.value;

    if ((<KeyboardEvent>(event)).key === 'Enter') {
      this.autocomplete.closePanel();
      this.unifiedSearchFilteredResult = [];
      this.unifiedSearchResult = [];
      this.unifiedSearchGroup = [];
      let telemetryEventData = {
        type: INTERACTEVENTS.ENTER,
        //subtype: this.data.split('_')[2],
        id: searchData + " " + INTERACTEVENTS.ENTER,
        pageid: Appconstants.people
      }
      this.telemetryService.interact(telemetryEventData);
      console.log("search data1", searchData);
      if (searchData.length > 0 && searchData.charAt(0) == '@') {
        console.log("search data1", searchData);
        searchData = searchData.split('@')[1] + '_' + 'people';
        console.log("search data2", searchData);
      }
      else {
        searchData = this.formControlSearch.value + "_" + "all";
      }

      this.router.navigate([Appconstants.unifiedsearch, { searchData }]);
    }
    else {
      if (!this.blnBenefitsLoad) {
        this.fnLogImpressionEvents("benefits request starts");
        this.tapItService.getBetaUsersData().subscribe((data: object[]) => {
          this.fnLogImpressionEvents("benefits request ends");
          console.log("getBenefitsIds");
          console.log(data);
          data.map((data) => {
            if (!this.benefitsData.includes(data['BenefitId'])) {
              this.benefitsData = this.benefitsData == "" ? data['BenefitId'] :
                this.benefitsData + "," + data['BenefitId'];
            }
          })

          this.blnBenefitsLoad = true;

          this.fnLogImpressionEvents("benefits binding ends");
          this.fnSearchResultForTypeahead(searchData);
        })
      }
      else {
        this.fnSearchResultForTypeahead(searchData);
      }

    }
  }

  fnSearchResultForTypeahead(searchData: string) {
    this.fnLogImpressionEvents("unifiedsearch request starts");
    let blnPeopleSearch: boolean = false;
    if (searchData.length > 0 && searchData.charAt(0) === '@') {
      let query: string[] = [];
      blnPeopleSearch = true;
      query = searchData.split("@");
      searchData = query[1];
    }

    this.tapItService.getUnifiedSearchResultForTypeahead(searchData, this.benefitsData, blnPeopleSearch).subscribe((searchResult: object[]) => { /* SonarQube fix */
      this.fnLogImpressionEvents("unifiedsearch response ends1");
      this.unifiedSearchFilteredResult = [];
      this.unifiedSearchResult = [];
      this.unifiedSearchGroup = [];
      console.log("unified search data");
      console.log(searchResult);
      if (searchResult.length > 0) {
        for (let result in searchResult) {
          this.unifiedSearchResult.push(searchResult[result]);
        }
      }
      this.unifiedSearchFilteredResult['result'] = this.unifiedSearchResult;
      console.log("unified search unifiedSearchFilteredResult");
      console.log(this.unifiedSearchResult);

      console.log("unifiedSearchFilteredResult1");
      console.log(this.unifiedSearchFilteredResult);
      this.fnLogImpressionEvents("unifiedsearch search binding ends");
    });
  }

  BindForm(id, type, txtsearch) {
    console.log("BindForm", type);
    if (type === Appconstants.launches) {
      // console.log(id);
      window.open(id, '_blank');
    }
    else if (type === 'M') {
      console.log('MicroApp');
      if (txtsearch.toLowerCase() === Appconstants.receiptStore) {
        this.router.navigate([Appconstants.receiptStoreNav]);
      }
      else if (txtsearch.toLowerCase() === Appconstants.raiseNewClaim) {
        this.router.navigate([Appconstants.claimsSubmissionRoute]);
      }
      else if (txtsearch.toLowerCase() === Appconstants.raiseDomestic) {
        this.router.navigate([Appconstants.domesticNav]);
      }
      else if (txtsearch.toLowerCase() === Appconstants.raiseInternational) {
        this.router.navigate([Appconstants.internationalNav]);
      }
    }
    else if (type === 'G') {

      let searchData: string = "";
      this.tapItService.getFormDetails(id).subscribe((data) => {
        searchData = data['configs'][0]['apiurl'];
        this.router.navigate([Appconstants.genericTemplate.replace("{0}", searchData)]);
      })


    }
    else {
      //this.getFormDetails(id + Appconstants.appendString + type);
      this.getFormDetails(id + Appconstants.appendString + type + Appconstants.appendString + txtsearch);
    }
  }

  getFormDetails(searchData: string) {
    console.log('search data', searchData);

    if (this.router.url.includes(Appconstants.tapIt)) {
      console.log('inside tapit');

      this.navigateToTapit(searchData);
    }
    else {
      console.log('inside tapelse');

      if (this.router.url.includes(Appconstants.faqRoutingPath)) {
        this.router.navigate([Appconstants.faqRoutingNavigate, { searchData }]);
      }
      else {
        this.navigateByurl(searchData);
      }
    }
  }

  navigateToTapit(searchData: string) {
    if (this.router.url.includes(Appconstants.faqRoutingPath)) {
      this.router.navigate([Appconstants.faqRoutingNavigate, { searchData }]);
    }
    else {
      this.router.navigate([Appconstants.tapitNavigate, { searchData }]);
      this.router.navigateByUrl(Appconstants.tapitNavigate, { skipLocationChange: true }).then(() =>
        this.router.navigate([Appconstants.tapitNavigate, { searchData }]));
    }
  }

  navigateByurl(searchData: string) {
    this.router.navigate([Appconstants.wallRouting, { searchData }]);
    this.router.navigateByUrl(Appconstants.wallRouting, { skipLocationChange: true }).then(() =>
      this.router.navigate([Appconstants.wallRouting, { searchData }]));
  }

  bindProfileImage() {
    console.log("inside profile");
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"))['firstName']
    this.headerService.getProfileImage().subscribe(profilePicObj => {


      if (profilePicObj['image1'] !== null && profilePicObj['image1'] !== Appconstants.empty) {
        this.imgServiceByte = 'data:image/png;base64,';
        this.imgByteData = profilePicObj['image1'];
        this.imgByte1 = this.imgServiceByte + profilePicObj['image1'];
        this.errorProfilePic = false;
      }
      else {
        this.errorProfilePic = true;
      }


    },
      err => {
        console.log(err);
        this.errorProfilePic = true;
      })


  }

  showHelpdeskMaintenanceMsg(content) {
    this.fnLogInteract("help_desk_maintenance_message");
    this.modalService.open(content, { windowClass: 'web_custom_modal DSA_modal-xlg' })

  }



  fnLogImpressionEvents(type) {
    let impressionEventData = {
      type: type,
      pageid: ComponentName.header,
      uri: window.location.href

    };
    this.telemetryService.impression(impressionEventData);
  }


  DSA_mainHamburger(eventObj) {
    this.codeCp.DSA_mainHamburger_Click(eventObj);
  }

  DSA_mainMenu() {
    this.codeCp.DSA_mainMenu_Click();
  }

  DSA_mainSearch() {
    this.codeCp.DSA_mainSearch_Click();
  }


  ripcolor = this.codeCp.ripcolor;

  login() {
    this.router.navigate([this.strDPRoute]);
  }

  loadProfile() {
    this.fnLogInteract("onclick_profile");
    this.router.navigate(['app-wall/app-generichome/generictemplate/' + environment.profileModuleId]);
  }

  loadFaqs() {
    this.router.navigate(['app-wall/app-generichome/generictemplate/' + environment.faqsModuleId]);
  }

  logout() {
    let token1 = this.keycloakService.getKeycloakInstance().idToken;
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("authenticated");
    localStorage.removeItem("access-token");
    window.location.replace(environment.Redirect_Uri + window.location.origin + "/app-welcome&id_token_hint=" + token1);
  }

  setActiveCls(eventObj) {
    this.codeCp.setActiveClass(eventObj);
  }

  clickactive(eventObj) {
    this.codeCp.clickactiveClass(eventObj);
  }

  subscribeToHeaderObs() {
    this.subscription =
      this.headerService.getProfilePic().subscribe((profilePicObj: object) => {
        console.log("profilePicObj observable");
        this.bindProfileImage();
      });
  }

  getOldPortal() {
    this.fnLogInteract("help_desk_header_icon_redirection");
    let hdntoken = localStorage.getItem("access-token");
    let hdnTargetPageToOpen = 4;
    let obj = { "hdntoken": hdntoken, "hdnTargetPageToOpen": hdnTargetPageToOpen }
    let url = environment.oldalumnilink;
    this.genericTemplateService.post(obj, url)
  }

  fnLogInteract(buttonName) {
    let telemetryEventData = {
      type: INTERACTEVENTS.CLICK,
      id: buttonName,
      pageid: ComponentName.header
    }
    this.telemetryService.interact(telemetryEventData);
  }
  fnError(error) {
    let errorEventData = {
      type: IMPRESSIONEVENTS.VIEW,
      pageid: ComponentName.header,
      uri: window.location.href,
      error: error
    };
    this.telemetryService.error(errorEventData);
  }

  trackAppliedJobs() {
    this.fnLogInteract("onclick_trackJobApplications");
    this.router.navigate(['app-wall/app-generichome/generictemplate/' + environment.jobsTrackingModuleId]);
  }
  raiseRequest() {
    this.router.navigate(['app-wall/app-generichome/generictemplate/' + environment.raiserequestModuleId]);

  }
  manageRequest() {
    this.router.navigate(['app-wall/app-generichome/generictemplate/' + environment.manageRequestmoduleId]);

  }

  navigateTo(heading, value,type) {
    if (heading == "Insurance") {
      this.fnLogInteract("Dashboard_onclick_insurance");
    } else if (heading == "Retirals") {
      this.fnLogInteract("Dashboard_onclick_retirals");
    } else if (heading == "All Documents") {
      this.fnLogInteract("Dashboard_onclick_documents");
    } else if (heading == "Raise a Request") {
      this.fnLogInteract("Dashboard_onclick_raiseRequest");
    } else if (heading == "Manage a Request") {
      this.fnLogInteract("Dashboard_onclick_manageRequest");
    } else if (heading == "Track Job Application") {
      this.fnLogInteract("Dashboard_onclick_trackJobApplications");
    } else if (heading == "Connect with Alumni") {
      this.fnLogInteract("Dashboard_onclick_connectwithAlumni");
    } else if (heading == "Feature as an Ambassador") {
      this.fnLogInteract("Dashboard_onclick_featureAsAmbassador");
    } else if (heading == "Contact Us") {
      this.fnLogInteract("Dashboard_onclick_contactUs");
    } else if (heading == "Restart with Infosys") {
      this.fnLogInteract("Dashboard_onclick_restartWithInfosys");
    } else if (heading == "Infosys in the news") {
      this.fnLogInteract("Dashboard_onclick_infosysInTheNews");
    } else if (heading == "Showcase") {
      this.fnLogInteract("Dashboard_onclick_showcase");
    } else if (heading == "Communities") {
      this.fnLogInteract("Dashboard_onclick_communities");
    } else if (heading == "Digest") {
      this.fnLogInteract("Dashboard_onclick_digest");
    } else if (heading == "Stories") {
      this.fnLogInteract("Dashboard_onclick_stories");
    } 
    if (type === "nonmenu") {
      window.open(value, '_blank');
    }
    else if (type == "E") {
      window.open('mailto:' + value);
    }
      // check modID is number or not
    else if (type == "U") {
      window.open(value, '_blank');
    }
    else  {
      this.router.navigate(['app-wall/app-generichome/generictemplate/' + value]);
    }
  }
}

export interface Cityname {
  value: string;
  viewValue: string;
}

