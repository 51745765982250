import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { GenerictemplateService } from '../form/generictemplate/generictemplate.service';
import {MessageSnackBarService} from '../shared/component/message-snackbar/message-snackbar.service';
import { Appconstants } from '../shared/constants/app-constants';

import { ComponentName } from "src/app/shared/constants/component-name.constants";
import { TelemetryService, INTERACTEVENTS ,IMPRESSIONEVENTS} from '@infosys-it/angular-telemetry-sdk';

@Component({
  selector: 'app-emailidchange',
  templateUrl: './emailidchange.component.html',
  styleUrls: ['./emailidchange.component.css']
})
export class EmailidchangeComponent implements OnInit {
  oldMailId:FormControl;
  newMailId:FormControl;
  dateOfJoining:FormControl;
  dateOfLeaving:FormControl;
  employeeNumber:FormControl;
  otpValue:FormControl;
  emailForm: FormGroup;
  strMessage: string;
  blnError: boolean;
  blnSuccess: boolean;
  reqinput: {};
  formattedDateOfJoining: string;
  formattedDateOfLeaving: string;
  blnShowOtpField: boolean =false;
  blnReadOnly: boolean = false;
  reqinput2: any;
  otp: any;
  btnenable: boolean =false;
  otpNumber: any;
  maxDate=new Date();
  blnShowLoader:boolean=false;
  
  constructor( private router:Router,private messageservice: MessageSnackBarService, private generictemplate:GenerictemplateService, private fb:FormBuilder,private telemetryService: TelemetryService) { }

  ngOnInit() {
    this.initiateForm();
//     this.fnLogImpression();
  }
//   fnLogImpression() {
//     let impressionEventData = {
//       type: IMPRESSIONEVENTS.VIEW,
//       pageid: ComponentName.emailaddresschange,
//       uri: window.location.href,
//       //subtype: this.data.split('_')[2]
//     };
//     this.telemetryService.impression(impressionEventData);
//   }
//   fnLogInteract(buttonName){
//     let telemetryEventData = {
//       type: INTERACTEVENTS.CLICK,
//       id: buttonName + "_buttonclick",
//       pageid: ComponentName.emailaddresschange
//     }
//     this.telemetryService.interact(telemetryEventData);
//   }


  public initiateForm() {
    this.emailForm = new FormGroup({
      // oldMailId:new FormControl('',[Validators.required,Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ]),
      oldMailId:new FormControl('',[Validators.required]),
      newMailId:new FormControl('',[Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ]), 
      dateOfJoining:new FormControl('',[Validators.required]),
      dateOfLeaving:new FormControl('',[Validators.required]),
      employeeNumber:new FormControl('',[ Validators.required, Validators.pattern(/^[0-9]*$/) ]),
      authentication: new FormGroup({
      otpValue: new FormControl('')

      })
    })}

  reset(){
   this.emailForm.value;
      return;

  }

  get formControl() {
    return this.emailForm.controls;
  }
   error:any={isError:false,errorMessage:''};

compareTwodates(){
   if(this.formControl.dateOfJoining.value>=this.formControl.dateOfLeaving.value){
    this.error= this.messageservice.openMessageBar({type:'error', message:"Please enter correct date of leaving"})
    return false;
    // this.error= {isError:true,errorMessage:' Date of can not before start date'} 
}
else{
  return true;
}
// else if(this.formControl.dateOfJoining.value<this.formControl.dateOfLeaving.value){

//   this.error= {isError:false,errorMessage:''}
// }
}


fnSubmit(){
  this.blnSuccess=false;
  this.blnError= false;
  this.blnShowLoader=true;
  console.log(this.emailForm.get('authentication').get('otpValue'))
  var datePipe = new DatePipe('en-US');
    this.formattedDateOfJoining = datePipe.transform(this.formControl.dateOfJoining.value, 'MM/dd/yyyy');
    this.formattedDateOfLeaving = datePipe.transform(this.formControl.dateOfLeaving.value, 'MM/dd/yyyy');
console.log(this.formControl.dateOfJoining.value)
  this.reqinput={
    
      "dateOfJoining": this.formattedDateOfJoining ,
      "dateOfLeaving": this.formattedDateOfLeaving,
      "empNo": this.formControl.employeeNumber.value,
      "newMailId": this.formControl.newMailId.value,
      "oldMailId": this.formControl.oldMailId.value
    
    
  }
  console.log(this.reqinput)
  let url:any;

  
  this.generictemplate.changeofmailid(this.reqinput).subscribe((data:any)=>
  {
    console.log(this.emailForm.value)
    console.log(data, typeof(data))
    if(data.find(x => x['type'].includes('success'))){
      this.blnShowLoader=false;
      this.blnShowOtpField = true;
      this.blnReadOnly = true;
      this.messageservice.openMessageBar({type:'success', message: data.find(x => x['type'].includes('success'))['content']});
      this.formControl.otpValue=this.emailForm.get('authentication').get('otpValue');
      this.emailForm.get('authentication').get('otpValue').setValidators([Validators.required, Validators.pattern(/^[0-9]*$/)])
      this.emailForm.get('authentication').get('otpValue').updateValueAndValidity({emitEvent:false, onlySelf:true})
      console.log("solu",this.formControl.otpValue,this.emailForm)
    }
    else if(data.find(x => x['type'].includes('error'))){
      this.blnShowLoader=false;
      this.messageservice.openMessageBar({type:'error', message: data.find(x => x['type'].includes('error'))['content']})
    }
  },
  err=>{
    console.log(err);
  }
  )
}

fnSubmitOtp(){
  this.blnShowLoader=true;
  console.log(this.blnShowOtpField,this.blnReadOnly)
this.otpNumber=this.formControl.otpValue.value
console.log(this.reqinput2)
this.generictemplate.updatemailid(this.otpNumber,this.reqinput).subscribe((data:any)=>
  {
    console.log(data, typeof(data))
    if(data.find(x => x['type'].includes('success'))){
      this.blnShowLoader=false;
//       this.fnLogInteract("Emailidupdate_success");
      this.messageservice.openMessageBar({type:'success', message: data.find(x => x['type'].includes('success'))['content']});
      this.router.navigate(['app-wall/app-generichome/generictemplate/'+environment.dpConsentModuleId]);
    }
    else if(data.find(x => x['type'].includes('error'))){
      this.blnShowLoader=false;
//       this.fnLogInteract("Emailidupdate_failure");
      this.messageservice.openMessageBar({type:'error', message: data.find(x => x['type'].includes('error'))['content']})
    }
   
   
  },
  err=>{
    console.log(err);
  }
  )
 
}
readStatus($event){
  console.log($event)
}

navigateback(){
  console.log(this.emailForm.value)
  this.router.navigate(['app-wall/app-generichome/generictemplate/'+environment.dpConsentModuleId]);
}

}
