import { Component, OnInit, ElementRef} from '@angular/core';
import { UserBase } from 'src/app/shared/base/user-base';
import { TelemetryService } from '@infosys-it/angular-telemetry-sdk';
import { Guid } from "guid-typescript";
import { IMPRESSIONEVENTS, EVENTTYPES } from 'src/app/shared/constants';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { environment } from 'src/environments/environment.prod';
import { AppUtilServices } from 'src/app/shared/services/app-util.services';
import { BnNgIdleService } from 'bn-ng-idle';

declare function bindData(userName:string,botName:string,baseURL:string,spellCheckInitializeCheckURL:string,spellCheckInitializeURL:string,correctSentenceURL:string,bestWordsURL:string,authToken,autosuggestion:string);
@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss']
})
export class WallComponent extends UserBase implements OnInit {
  userName:string='';
  botName:string;
  baseUrl:string;
  autosuggestion:string
  spellCheckInitializeCheckURL:string;
  spellCheckInitializeURL:string;
  correctSentenceURL:string;
  bestWordsURL:string;
  blShowBot:boolean=false;
  public blnShowinfo: boolean = false;
  BATCH_SIZE: number = 1;
  

  ripcolor=this.codeCp.ripcolor;
  blnFeedbackPopup: boolean =false;
  blnChatbotPopup: boolean =false;
  
  setActiveCls(eventObj){    
  this.codeCp.setActiveClass(eventObj);
  }


  
  //show hide menu
  DSA_mainHamburger(eventObj){
  this.codeCp.DSA_mainHamburger_Click(eventObj);
  }
  
  DSA_mainMenu(){
  this.codeCp.DSA_mainMenu_Click();
  }
  
  DSA_mainSearch(){
  this.codeCp.DSA_mainSearch_Click();
  }
  constructor(private telemetryService: TelemetryService
    , private codeCp: AppUtilServices,
    private bnIdle: BnNgIdleService)
    {
      super();
      this.fnTelemetryStartLog();
      window.onbeforeunload = function() {
        return 'Dialog text here.';
      };
    
        this.bnIdle.startWatching(environment.idlesessiontime).subscribe((res) => {
          if(res) {
              alert("Your session timed out. Please login again to continue.");
        
          }
        })
    
  }
  
  ngOnInit() {
    this.codeCp.middleHeight();
    this.getBotData();
    sessionStorage.setItem('pid',Guid.create().toString());
   
  
    let impressionEventData = {
      type: IMPRESSIONEVENTS.DETAIL,
      pageid:Appconstants.wall,
      uri: window.location.href 
      };
    this.telemetryService.impression(impressionEventData);
    
    let url = window.location.href;
    if (url.includes(Appconstants.znch)) {
      setTimeout(() => {
        this.blnShowinfo = false;
      }, 5000);
      this.blnShowinfo = true;
    }
    else {
      this.blnShowinfo = false;
    }

  }

  

  fnTelemetryStartLog() {
    // console.log("start log telemetry", localStorage.getItem("access-token"));
    let config = {
      
      "type": Appconstants.app, 
      "actor": { 
        "id": this.mailId,
        "type":   this.name
      },
        "channel": "alumni.infosys." +environment.type + ".web",

      "pdata": {
        "id": Appconstants.GAP,
        "ver": Appconstants.ver,
        "pid":  sessionStorage.getItem('pid')
      },
      "object":{"id":"contentId","ver":Appconstants.ver},
      "env": Appconstants.GAP,
   
        "sid":  sessionStorage.getItem('pid'),
   
      "batchsize": this.BATCH_SIZE,
      "host": environment.telemetryBaseUrl,
      "endpoint": environment.telemetryEndPoint,
      "apislug": "",
      "authtoken": localStorage.getItem("access-token")
    }
    let options = { 
      actor: { 
       "id": this.idForTelemetry,
      "type":   this.name
    }, 
    context:{
    "channel": "alumni.infosys." + environment.type + ".web.internet"
    },
      
    pdata: {
      "id": Appconstants.GAP,
      "ver": Appconstants.ver,
      "pid":  sessionStorage.getItem('pid')
    },
   
    sid:  sessionStorage.getItem('pid')
  }

    this.telemetryService.provider = 'SUNBIRD';
    this.telemetryService.setProviderImplementation('SUNBIRD');
    let startData = {
      type: EVENTTYPES.APP
        };
    this.telemetryService.start(config,'contentId1', 'contentVer1', startData,options);
  }
  feedbackaction(){
    this.blnFeedbackPopup = true;
  }
  chatbotaction(){
    this.blnChatbotPopup = true;
  }

  closeModal(event){
    console.log(event,"closemodal event")
    this.blnFeedbackPopup=event;
    this.blnChatbotPopup=event;
  }
  getBotData(){
    if(environment.blBotFlag=='enabled'){
      this.blShowBot=true;
    }
    else{
      this.blShowBot=false;
    }
    let authToken=localStorage.getItem("access-token");
    // this.userName= localStorage.getItem("loggedInUser")['firstName']+" "+localStorage.getItem("loggedInUser")['lastName'];
    console.log("authToken",authToken);
    this.botName=environment.botName;
    console.log("appname",this.botName);
    this.baseUrl=environment.baseURL;
    this.spellCheckInitializeCheckURL=""
    this.spellCheckInitializeURL=""
    this.correctSentenceURL=""
    this.bestWordsURL=""
    this.autosuggestion = environment.autosuggestion
    bindData(this.userName,this.botName,this.baseUrl,this.spellCheckInitializeCheckURL,this.spellCheckInitializeURL,this.correctSentenceURL,this.bestWordsURL,authToken,this.autosuggestion);
  }
  
}
