
import {Component, OnInit } from '@angular/core';
import { AppUtilServices } from 'src/app/shared/services/app-util.services';
import { NavMenuService } from './nav-menu.service';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  jsonArr: any;

  constructor( private codeCp: AppUtilServices , private service : NavMenuService) { }

  ngOnInit() {
    console.log("came llll");
   this.menuDetails();
  }
  menuDetails(){
    this.service.getDetails().subscribe((data: object[]) =>{
      this.jsonArr=data;
      console.log("yesHere",this.jsonArr);
      
    })
  }
  DSA_mainHamburger(eventObj){
    this.codeCp.DSA_mainHamburger_Click(eventObj);
    }
    setActiveCls(eventObj, objNavBar) {
      this.codeCp.setActiveClass(eventObj);
      console.log("setActiveCls", eventObj,objNavBar);
    }
    
  setActiveClsChild(eventObj,objNavBar){    
    this.codeCp.setActiveClass(eventObj);
    console.log("setActiveClsChild",eventObj,objNavBar);
  }
}
