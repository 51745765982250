import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../modules/app-material.module';
import { AppPluginsModule } from '../modules/app-plugins.module';
import { AppBootstrapModule } from '../modules/app-bootstrap.module';
@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        AppPluginsModule,
        AppBootstrapModule
    ],
    imports: [CommonModule,
    ],
    declarations: [],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {

}