import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JavaServiceBase } from 'src/app/shared/base/javaservice-base';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class NavMenuService extends JavaServiceBase {

  constructor(private httpClient: HttpClient) {
    super();
   }

  getDetails() {
    const envUrl: string = environment.menu;
    return this.httpClient.get(envUrl);
  }
}
