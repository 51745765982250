import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MessageSnackbarComponent } from './message-snackbar.component';

type Success = 'success';
type Error = 'error';
type Info = 'info';
type Warning = 'warning';

type MessageType = Success | Error | Info | Warning;

export interface SnackBarData {
    type: MessageType, message: string
}

@Injectable({
    providedIn: 'root'
})
export class MessageSnackBarService {

    private durationInSeconds = 15;
    private config:MatSnackBarConfig;

    constructor(public matSnackBar: MatSnackBar, private ngZone:NgZone) {
        this.config = new MatSnackBarConfig();
        this.config.panelClass = ['message-snackbar-container'];
        this.config.verticalPosition = 'top';
        this.config.horizontalPosition = 'center';
        this.config.duration = this.durationInSeconds * 1000;
    }

    /**
     * @param data - { type: string, message: string }
     * @param type - Can take only 'success' | 'error' | 'warning' | 'info'
     */
    openMessageBar(data: SnackBarData) {
      this.matSnackBar.dismiss();
      this.config.data = data;
      this.ngZone.run(()=>{
        this.matSnackBar.openFromComponent(MessageSnackbarComponent, this.config);
      });
    }

    closeMessageBar() {
        this.matSnackBar.dismiss();
    }
}

// {
//     duration: this.durationInSeconds * 1000,
//     horizontalPosition:'center',
//     verticalPosition:'top',
//     panelClass:"",
//     data: data
//   }