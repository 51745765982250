
import { Appconstants } from '../constants/app-constants';
import { ApplyLeave, ApplyCompoff, ApplyAdvanceLeave } from '../models/leave.model';

export class UserBase{
  public name: string;
  public mailId: string;
  public idForTelemetry: string = "";

  
    constructor(){
      let loggedInUser: object = null;
      //comment rs 
      //   console.log(this.authService.loggedInUser(),this.authService.loggedInUserEmail());
      //   this.name = this.authService.loggedInUser().indexOf(' ') > 0 ? this.authService.loggedInUser().split(" ")[0] : this.authService.loggedInUser();
      //   this.mailId = this.authService.loggedInUserEmail();
        
      //  if(this.authService.loggedInUserEmail() !== "" && this.authService.loggedInUserEmail() !== null &&
      //  this.authService.loggedInUserEmail() !== undefined){
      //    this.idForTelemetry = this.authService.loggedInUserEmail().split("@")[0];
      //  }
     loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
     if(loggedInUser){
       this.mailId = loggedInUser['email'];
       this.name = loggedInUser['firstName'];
       if(this.mailId)
       this.idForTelemetry = this.mailId.split("@")[0]
     }
       console.log("Loggedinusermail", this.mailId, this.name, this.idForTelemetry);
    }

    convertGenModel(data: any, txtSearch : string): any {
      let leaveData: ApplyLeave;
      let compoffData: ApplyCompoff;
      let advLeaveData: ApplyAdvanceLeave;
      let fromDayType : string;
      let toDayType : string;

      if (txtSearch == "apply leave") {
        if (data['ToDayType'] == "Full") {
          toDayType = "1.0"
        }
        else {
          toDayType = "0.5"
        }
        if (data['FromDayType'] == "Full") {
          fromDayType = "1.0"
        }
        else {
          fromDayType = "0.5"
        }
  
        leaveData = {
          applicantMailId: "",
          applicantEmployeeNumber: "",
          approverEmployeeNumber: "",
          approverMailId: "",
          leaveType: "",
          fromDate: "",
          toDate: "",
          fromPartOfTheLeave: "",
          toPartOfTheLeave: "",
          reason: "",
          leavePurpose: ""
        };
        leaveData.applicantMailId = this.mailId;
        leaveData.fromDate = this.convertToDateString(data['FromDate']); 
        leaveData.fromPartOfTheLeave = fromDayType
        leaveData.leaveType = data['LeaveType']
        leaveData.reason = data['Reason']
        leaveData.toDate = this.convertToDateString(data['ToDate']);  
        leaveData.toPartOfTheLeave = toDayType
        leaveData.approverMailId = data['ApproverId']
        return leaveData;
      }
  
      else if (txtSearch == "create compoff") {
        compoffData = {
          approverEmployeeNumber: "",
          approverMailId: "",
          applicantEmployeeNumber: "",
          applicantMailId: "",
          applicantName: "",
          compOffDate: "",
          dmMailId: "",
          flagPaymentType: "",
          reason: "",
          workLocation: ""
        };
        compoffData.approverMailId = data['ApproverId']
        compoffData.applicantEmployeeNumber = '';
        compoffData.applicantMailId = this.mailId;
        compoffData.applicantName = this.name;
        compoffData.compOffDate = this.convertToDateString(data['CompOffDate']);
        compoffData.flagPaymentType = "No"; 
        compoffData.reason = data['Category'];
        compoffData.workLocation = data['PaidCompOff'];
        compoffData.dmMailId = data['DmMailId'];
        return compoffData;
      }
  
      else if (txtSearch == "apply advance leave") {
        advLeaveData = {
          applicantMailId: "",
          approverMailId: "",
          reason: ""
        };
        advLeaveData.applicantMailId = this.mailId;
        advLeaveData.approverMailId = data['ApproverId'];
        advLeaveData.reason = data['Reason'];
        return advLeaveData;
      }
    }
  
    convertToDateString(momentDate): string {
      let dateCurr = '';
      let month = -1;
      let year = '';
      let strDateString: string = "";
      if (momentDate != Appconstants.empty && momentDate != Appconstants.undefined) {
        for (let date in momentDate['_i']) {
          if (date === Appconstants.dateString || date === '2') {
            if (momentDate['_i'][date] <= 9) {
              dateCurr = '0' + momentDate['_i'][date];
            }
            else {
              dateCurr = momentDate['_i'][date];
            }
          }
          if (date === Appconstants.monthString || date === '1') {
            month = momentDate['_i'][date];
          }
          if (date === Appconstants.yearString || date === '0') {
            year = momentDate['_i'][date];
          }
        }
        strDateString = dateCurr + '-' + this.getMonthString(month) + '-' + year;
      }
      return strDateString;
    }
  
  
    getMonthString(month: number) {
      let value: string;
      switch (month) {
        case 0: {
          value = 'Jan'
          break;
        }
        case 1: {
          value = 'Feb'
          break;
        }
        case 2: {
          value = 'Mar'
          break;
        }
        case 3: {
          value = 'Apr'
          break;
        }
        case 4: {
          value = 'May'
          break;
        }
        case 5: {
          value = 'Jun'
          break;
        }
        case 6: {
          value = 'Jul'
          break;
        }
        case 7: {
          value = 'Aug'
          break;
        }
        case 8: {
          value = 'Sep'
          break;
        }
        case 9: {
          value = 'Oct'
          break;
        }
        case 10: {
          value = 'Nov'
          break;
        }
        case 11: {
          value = 'Dec'
          break;
        }
        default: {
          break;
        }
      }
      return value;
    }
  
} 


