import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-down-time-msg',
  templateUrl: './down-time-msg.component.html',
  styleUrls: ['./down-time-msg.component.scss']
})
export class DownTimeMsgComponent implements OnInit {

  blnLoad:boolean = false;
  @Input() msgObj;
  constructor() { }

  ngOnInit() {
    this.blnLoad = true
  }

}
