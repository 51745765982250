import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { Appconstants } from "../constants/app-constants";
import { AppConfigService } from "../helpers/app-config.service";

@Injectable({
    providedIn: 'root'
})
export class AppDownTimeService {

    private http: HttpClient;
    constructor(private handler: HttpBackend) {
        this.http = new HttpClient(this.handler);
    }

    getAppDownObj() {
        let url: string = AppConfigService.appEnvData.appDownJsonLink;
        return new Promise<void>((resolve,reject)=>{
            this.http.get<any>(url).toPromise().then((response)=>{
               console.log("response in app down service "+JSON.stringify(response));
               Appconstants.AppDownJSON = response;
               resolve();
            }).catch((err) =>{
                let message = err.message+' '+err.status;
                console.log("error occurred in app down service "+ message);
                Appconstants.AppDownJSON=JSON.parse("[{\"isAppUp\":\"0\",\"strMainContent\":\"Website is currently offline for scheduled maintenance.\",\"strContent\":\"We expect to be back in a couple of hours. Thanks for your patience.\"}]")
               // reject(message);
               resolve();
            });
        });
    }
}